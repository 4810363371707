import {filterStateActions} from '@app/components/right-sidebar/redux';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ExpenseDatesGrid} from '../expense-dates-grid/expensedategrid';
import {fetchExpenseTypeDateGridCreator} from '../redux/slice';
import {ConfigurationManagementSelector} from '../redux/seletors';
import {CircularProgress} from '@mui/material';
import {Box} from '@mui/system';
import {useStyles} from '@app/screens/Styles/style';

export const ExpenseDates = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const getExpenseDateData = useSelector(
    ConfigurationManagementSelector.getExpenseTypeDate(),
  );
  const getExpenseDataLoading = useSelector(
    ConfigurationManagementSelector.getExpenseDateLoading(),
  );
  useEffect(() => {
    dispatch(fetchExpenseTypeDateGridCreator());
    return () => {
      dispatch(filterStateActions.setFilterState({}));
    };
  }, [dispatch]);

  return (
    <div>
      {getExpenseDataLoading ? (
        <Box className={classes.justifyContentcenterflex}>
          <CircularProgress />
        </Box>
      ) : (
        <ExpenseDatesGrid expdata={getExpenseDateData?.data ?? []} />
      )}
    </div>
  );
};
