import {routeSelector} from '@app/router/redux';
import {Button, CircularProgress} from '@mui/material';
import * as Excel from 'exceljs';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

const ExcelReportDownloadCommon = ({
  data = [],
  fileName,
  Header,
  buttonTitle,
  disableButton,
  onClick,
  resetFunction,
  division,
  staff,
  sap,
  designation,
  hqName,
  state,
  searchedItem,
  subordinate,
}: any) => {
  const navbarComponentNameSelector = useSelector(
    routeSelector.getNavbarComponentName(),
  );
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (loader && data.length > 0) {
      exportDataToExcel();
    }
  }, [data, loader]);

  const fitToColumnExcelJs = (worksheet: any, data: any[]) => {
    let index = 1;
    for (const property in data[0]) {
      const maxColumnWidth = Math.max(
        property ? property.toString().length * 10 : 0,
        ...data.map(obj =>
          obj[property] ? obj[property].toString().length * 10 : 0,
        ),
      );
      if (index === 7 || index === 8) {
        worksheet.getColumn(index).width = 30;
        index++;
      } else {
        worksheet.getColumn(index).width =
          maxColumnWidth < 110 ? 20 : maxColumnWidth / 7;
        index++;
      }
    }
  };

  const base64DownloaderMiscWeb = (excelBuffer: BlobPart) => {
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const linkSource = URL.createObjectURL(blob);
    const downloadLink = document?.createElement('a')
      ? document?.createElement('a')
      : {href: '', download: '', click: () => true};
    downloadLink.href = linkSource;
    downloadLink.download = `${fileName}.xlsx`;
    downloadLink.click();
    resetFunction();
    return null;
  };

  const CallExcelData = () => {
    setLoader(true);
    onClick();
  };
  const HeadersArr = Header.map((item: any) => item.title);
  const exportDataToExcel = async () => {
    let wb = new Excel.Workbook();
    let ws = wb.addWorksheet('REPORT');
  
    // Helper function to add and merge a row
    const addMergedRow = (rowContent: string, rowIndex: number) => {
      let row = ws.addRow([rowContent]);
      if (!ws.getCell(`A${rowIndex}`).master) {
        ws.mergeCells(`A${rowIndex}:H${rowIndex}`);
      }
      row.getCell(1).alignment = {horizontal: 'left'};
    };
  
    // Add header rows based on navbarComponentNameSelector
    const addHeaderRows = () => {
      if (navbarComponentNameSelector === 'LedgerReport') {
        addMergedRow(`Staff Name: ${staff}`, 1);
        addMergedRow(`SAP Code: ${sap}`, 2);

        addMergedRow(`Division: ${division}`, 3);
        addMergedRow(`Designation Name: ${designation}`, 4);
      }
    };
  
    // Add additional rows
    const addAdditionalRows = () => {
      if (state) {
        addMergedRow(`State Name: ${state}`, ws.actualRowCount + 1);
      }
      if (hqName) {
        addMergedRow(`HQ Name: ${hqName}`, ws.actualRowCount + 1);
      }
      if (searchedItem) {
        addMergedRow(`Search For: ${searchedItem}`, ws.actualRowCount + 1);
      }
      if (subordinate) {
        addMergedRow(`Subordinate's Name: ${subordinate}`, ws.actualRowCount + 1);
      }
      if (navbarComponentNameSelector === 'LedgerReport') {
        // Add an empty row
        ws.addRow([]);
      }
    };
  
    // Add header and additional rows
    addHeaderRows();
    addAdditionalRows();
  
  
    // Prepare and add excel data rows
    const formatCellValue = (itemName: any, cellValue: string) => {
      if (itemName === 'transactionDate') {
        return cellValue.split('T')[0];
      }
      return cellValue;
    };
    
    const prepareRowData = (rowData: { [x: string]: any; }) => {
      return Header.map((item: { name: string | number; }) => formatCellValue(item.name, rowData[item.name]));
    };
    
    const prepareExcelData = () => {
      return data.map(prepareRowData);
    };
    
    const excelData = prepareExcelData();
    const finalExcelData = [
      [`${fileName}`, '', '', '', '', '', '', ''],
      [...HeadersArr],
      ...excelData,
    ];
  
    ws.addRows(finalExcelData);
    ws.mergeCells(1, 1, 1, 8);
    fitToColumnExcelJs(ws, excelData);
  
    // Write to buffer and download
    wb.xlsx.writeBuffer().then(async (excelBuffer) => {
      setLoader(false);
      base64DownloaderMiscWeb(excelBuffer);
    });
  };

  return (
    <Button
      data-testid={'Report-download'}
      variant="contained"
      size="small"
      onClick={CallExcelData}
      disabled={disableButton}
      sx={{textTransform: 'none', height: 30, left: 5, width: 150}}>
      {' '}
      {loader ? <CircularProgress color="inherit" size={20} /> : buttonTitle}
    </Button>
  );
};

export default ExcelReportDownloadCommon;
