import {createAction, createSlice} from '@reduxjs/toolkit';
export const navbarComponentName = {
  home: 'Home',
  accessManagement: 'AccessManagement',
  accessGroupMaster: 'AccessGroupMaster',
  planAndMeet: 'PlanAndMeet',
  dcrUnlockRequest: 'DcrUnlockRequest',
  positionManagement: 'PositionManagement',
  approvalWorkflow: 'ApprovalWorkflow',
  inTransitChallan: 'InTransitChallan',
  inTransitAccess: 'inTransitAccess',
  cnfReturnItems: 'CnfReturnItems',
  cnfReturnItemsDetail: 'CnfReturnItemsDetail',
  inventoryConfigurator: 'InventoryConfigurator',
  expectedUtilizationDate: 'ExpectedUtilizationDate',
  approveReturnItems: 'ApprovedReturnedItems',
  inventoryRules: 'InventoryRules',
  statusManager: 'StatusManager',
  Expense: 'Expense',
  MasterDataManagement: 'MasterDataManagement',
  ComponentManagement: 'ComponentManagement',
  ConfigurationManagement: 'ConfigurationManagement',
  commonheader: 'CommonHeader',
  moduleSpecificUtilities: 'ModuleSpecificUtilities',
  approveExpenses: 'ApproveExpenses',
  ChallanShortQtyApproval: 'ChallanShortQtyApproval',
  activityMapping: 'ActivityMapping',
  ProRataMiscExpense: 'ProRataMiscExpense',
  gspSchedule: 'GspSchedule',
  pendencyReport: 'PendencyReport',
  ledgerReport: 'LedgerReport',
  dataCorrection: 'DataCorrection',
  holidayManagement: 'HolidayManagement',
  leaveManagement: 'LeaveManagement',
  onboardNewDivision: 'OnboardNewDivision',
  inventoryCompliance: 'InventoryCompliance',
};

export const routesInitialState = {
  currentRoute: '',
  isRoutesLoading: false,
  routesRenderingError: false,
  routesenderingErrorMessage: '',
  sideBarOpen: true,
  appRoutes: {},
  navbarComponentName: navbarComponentName.home,
  navbarComponentHeading: '',
};

export const fetchAppRoutesCreator = createAction(
  'ROUTE_HANDLER/FETCH_APP_ROUTES',
);
export const fetchAppRoutesCreatorTypeName = fetchAppRoutesCreator().type;

const routeStateHandler = createSlice({
  name: 'ROUTE_HANDLER',
  initialState: routesInitialState,
  reducers: {
    updateScreen: (prevState: any, action: {payload: any}) => {
      const {payload} = action;
      return {
        ...prevState,
        currentRoute: payload,
      };
    },
    setRoutesLoading: (prevState: any, action: {payload: any}) => {
      const {payload} = action;
      return {
        ...prevState,
        isRoutesLoading: payload,
      };
    },

    setRoutesRenderingError: (prevState: any, action: {payload: any}) => {
      const {payload} = action;
      return {
        ...prevState,
        routesRenderingError: payload,
      };
    },
    setRoutesRenderingErrorMessage: (
      prevState: any,
      action: {payload: any},
    ) => {
      const {payload} = action;
      return {
        ...prevState,
        routesenderingErrorMessage: payload,
      };
    },

    clearError: (prevState: any) => {
      return {
        ...prevState,
        routesRenderingError: false,
        routesenderingErrorMessage: '',
      };
    },

    setAppRoutes: (prevState: any, action: {payload: any}) => {
      const {payload} = action;
      return {
        ...prevState,
        appRoutes: payload,
      };
    },
    setSideBarOpen: (prevState: any, action: {payload: any}) => {
      const {payload} = action;
      return {
        ...prevState,
        sideBarOpen: payload,
      };
    },

    setNavbarComponentName: (prevState: any, action: {payload: any}) => {
      const {payload} = action;

      return {
        ...prevState,
        navbarComponentName: payload,
      };
    },

    setNavbarComponentHeading: (prevState: any, action: {payload: any}) => {
      const {payload} = action;

      return {
        ...prevState,
        navbarComponentHeading: payload,
      };
    },
  },
});

export const routeStateActions = routeStateHandler.actions;
export const routeStateReducer = routeStateHandler.reducer;
