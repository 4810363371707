import PropTypes from 'prop-types';
import ExcelReportDownloadCommon from './common';

const ExcelPdfReport = ({
  data,
  fileName,
  header,
  title,
  onClick,
  disableButton,
  resetFun,
  headerTitle,
  division,
  staff,
  sap,
  designation,
  hqName,
  state,
  searchedItem,
  subordinate,
}: any) => {
  return (
    <ExcelReportDownloadCommon
      data={data}
      fileName={fileName}
      Header={header}
      buttonTitle={title}
      onClick={onClick}
      disableButton={disableButton}
      resetFunction={resetFun}
      headerTitle={headerTitle}
      division={division}
      staff={staff}
      designation={designation}
      hqName={hqName}
      state={state}
      searchedItem={searchedItem}
      subordinate={subordinate}
      sap={sap}
    />
  );
};

ExcelPdfReport.propTypes = {
  data: PropTypes.any,
  fileName: PropTypes.string,
  header: PropTypes.any,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disableButton: PropTypes.bool,
  resetFun: PropTypes.func,
  headerTitle: PropTypes.any,
  division: PropTypes.any,
  staff: PropTypes.any,
  sap: PropTypes.any,
  designation: PropTypes.any,
  hqName: PropTypes.any,
  state: PropTypes.any,
  searchedItem: PropTypes.any,
  subordinate: PropTypes.any
};

export default ExcelPdfReport;
