import {DialogProps} from '@app/App';
import {Item, MenuProp, useStyles} from '@app/screens/Styles/style';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {DatePickerStyle} from '@app/components/widgets/datepickerStyle';
import {expenseReportsStateSelector} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/selectors';
import {
  expenseReportsActions,
  fetchExpenseReportBICreator,
} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/slice';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {Stack} from '@mui/system';
import {
  ExpenseBifurcationReport,
  parentHeader,
} from './ExpenseBifurcationReport';
import {
  approveExpenseStateActions,
  fetchDivisionDataCreator,
  fetchStaffDataCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {SEARCH_OBJECT} from '@app/screens/module-specific-utilities/module-specific-utilities.types';
import jsPDF from 'jspdf';
import {saveAs} from 'file-saver';
import Excel from 'exceljs';
import {routeStateActions} from '@app/router/redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {fitToColumnExcelJsAoA, style} from '@app/screens/module-specific-utilities/components/expense-reports/yearly-disbursement-summary/yearlySummaryHeader';
import moment from 'moment';
import {withPreventDoubleClick} from '@app/components/hoc';
import { strings } from '@app/common/strings';

export const utilBifurcate: any = {
  handleChangeDAMasterStartDates: null,
  handleChangeDAMasterEndDates: null,
  debouncedStaffSearch: null,
  editStaffHandler: null,
  handleChangeDivisionId: null,
  preventDefaultHandle: null,
  downloadExcel: null,
  onStaffChange: null,
  generatePDF: null,
  preventDefaultHandleDAMasterAdd: null,
  handleOnClickGo: null,
};
const headers = [
  'S.No',
  'Emp. Code',
  'Name',
  'Designation',
  'D.O.J',
  'Head Qtr',
  'State',
  'Month',
  'Conveyance_Expenses',
  'T.A',
  'Toll Tax',
  'Flight/Train Fare',
  'Taxi Fare',
  'Railway Pass',
  'Travelling_Expense',
  'Telephone And Net',
  'Postage & Courier',
  'Trans.',
  'Rikshaw',
  'Freight & Cartage',
  'Stationary',
  'Xerox',
  'Printing & Stationary',
  'Misc. Expense',
  'Member & Subscription',
  'Training_Sales',
  'Busi. Promo Exp.',
  'MOIE 194C',
  'MOIE 194J',
  'MOIE 194I',
  'MOIE 194Q',
  'MOIE 194H',
  'MOIE 194 NO TDS',
  'Amount',
];
export const tableRowKeys = [
  'sapCode',
  'staffFullName',
  'designationName',
  'dateOfJoining',
  'headQuaterName',
  'stateName',
  'expenseMonth',
  'conveyanceExpenseAmount',
  'taAmount',
  'taTollTaxAmount',
  'taFlightTrainAmount',
  'taTaxiFareAmount',
  'taRailwayPassAmount',
  'travellingExpenseAmount',
  'telephoneAndInternetAmount',
  'courierAndPostalAmount',
  'transTransportAmount',
  'transRickshawAmount',
  'freightAndCartageAmount',
  'stationaryAmount',
  'xeroxAmount',
  'printingAndStationaryAmount',
  'miscExpenseAmount',
  'memberSubscriptionAmount',
  'trainingAndSalesAmount',
  'businessPromoExpAmount',
  'moiE_194_C_Amount',
  'moiE_194_J_Amount',
  'moiE_194_I_Amount',
  'moiE_194_Q_Amount',
  'moiE_194_H_Amount',
  'moiE_194_NO_TDS_Amount',
  'totalAmount',
];
export const totSummaryKeys = [
  'conveyanceExpenseAmount',
  'taAmount',
  'taTollTaxAmount',
  'taFlightTrainAmount',
  'taTaxiFareAmount',
  'taRailwayPassAmount',
  'travellingExpenseAmount',
  'telephoneAndInternetAmount',
  'courierAndPostalAmount',
  'transTransportAmount',
  'transRickshawAmount',
  'freightAndCartageAmount',
  'stationaryAmount',
  'xeroxAmount',
  'printingAndStationaryAmount',
  'miscExpenseAmount',
  'memberSubscriptionAmount',
  'trainingAndSalesAmount',
  'businessPromoExpAmount',
  'moiE_194_C_Amount',
  'moiE_194_J_Amount',
  'moiE_194_I_Amount',
  'moiE_194_Q_Amount',
  'moiE_194_H_Amount',
  'moiE_194_NO_TDS_Amount',
  'totalAmount',
];
const generateTableRow = (data: any, tableRowKeys: any) => {
  return data?.items?.map((tableRow: any, index: any) => {
    const rowArrays = [
      index + 1,
      ...tableRowKeys.map((row: any) => {
        if (row === 'expenseMonth') {
          const month = moment(tableRow[row], 'M').format('MMM');
          const year = tableRow['expenseYear'];
          return `${month}'${year}`;
        } else if (row === 'dateOfJoining') {
          return moment(tableRow[row])?.format('D-MMM-YY');
        } else {
          return tableRow[row];
        }
      }),
    ];
    return rowArrays;
  });
};
const generateWithTotalRows = (
  data1: any,
  tableRowKeyOf: any,
  totalRow: any,
) => {
  return data1?.items
    ?.map((tableRow: any, index: any) => {
      const rowArrayOf = [
        index + 1,
        ...tableRowKeyOf.map((row: any) => {
          if (row === 'expenseMonth') {
            const month = moment(tableRow[row], 'M').format('MMM');
            const year = tableRow['expenseYear'];
            return `${month}'${year}`;
          } else if (row === 'dateOfJoining') {
            return moment(tableRow[row])?.format('D-MMM-YY');
          } else {
            return tableRow[row];
          }
        }),
      ];
      return rowArrayOf;
    })
    .concat([totalRow]);
};
const BifurcationHeader: React.FC<{setReportBack: any}> = ({setReportBack}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [validated, setValidated] = useState(true);
  const [currentStaff, setCurrentStaff] = useState<any[]>([]);
  const [expenseDAMasterEndDates, setExpenseDAMasterEndDates] =
    useState<any>(null);
  const [expenseDAMasterStartDates, setExpenseDAMasterStartDates] =
    useState<any>(null);
  const [expenseDAMasterMaxDate, setExpenseDAMasterMaxDate] = useState('');

  const handleChangeDivisionId: any = (value: number, type: string) => {
    dispatch(
      expenseReportsActions.setFiltersBI({
        [type]: value,
      }),
    );
    dispatch(expenseReportsActions?.setSelectedStaffData([]));
    dispatch(approveExpenseStateActions?.setStaffData([]));
    setCurrentStaff([]);
    setExpenseDAMasterStartDates(null);
    setExpenseDAMasterEndDates(null);
  };
  const tableData = useSelector(
    expenseReportsStateSelector.getReportBifurcate(),
  );
  const filteredData = useSelector(
    expenseReportsStateSelector.getFiltersBifurcation(),
  );

  const DebouncedButton = withPreventDoubleClick(Button, 400);

  useEffect(() => {
    dispatch(fetchDivisionDataCreator());
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Button
            data-testid="bonReport"
            onClick={() => setReportBack('')}
            style={{alignSelf: 'flex-start'}}>
            <ArrowBackIosIcon />
          </Button>
          <div
            className={classes.navbarComponentHeadingEmpty}
            style={{padding: 4}}>
            Expense Bifurcation
          </div>
        </div>,
      ),
    );
    return () => {
      dispatch(expenseReportsActions.resetFiltersTA());
      dispatch(
        routeStateActions.setNavbarComponentHeading(
          <div className={classes.navbarComponentHeadingEmpty}>
            Expense Reports
          </div>,
        ),
      );
    };
  }, []);

  const divisionData = useSelector(
    approveExpenseStateSelector?.getDivisionData(),
  );
  const selectedStaffData = useSelector(
    expenseReportsStateSelector?.getSelectedStaffData(),
  );
  const staffDatas: SEARCH_OBJECT[] = useSelector(
    approveExpenseStateSelector?.getStaffData(),
  );
  const preventDefaultHandleDAMasterAdd = useCallback(
    e => e.preventDefault(),
    [],
  );
  const handleChangeDAMasterEndDates = useCallback(
    (event: any) => {
      setExpenseDAMasterEndDates(event);
    },
    [expenseDAMasterEndDates],
  );
  const division = tableData?.header?.divisionName;
  let totalRow = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Total',
    ...Object.values(tableData?.summary || {}),
  ];
  const arrayOfArraysData = generateTableRow(tableData, tableRowKeys);
  const arrayOfArraysDataWithTotal = generateWithTotalRows(
    tableData,
    tableRowKeys,
    totalRow,
  );

  const downloadExcel = () => {
    const wb = new Excel.Workbook();
    const fromDate = new Date(expenseDAMasterStartDates);
    const toDate = new Date(expenseDAMasterEndDates);
    const formattedFromDate = moment.utc(fromDate).local().format('DD/MM/YYYY');
    const formattedToDate = moment.utc(toDate).local().format('DD/MM/YYYY');

    const ws = wb.addWorksheet('Expense bifurcation');
    ws.addRow([]);

    const heading = ws.addRow([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Expense Bifurcation',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      `From: ${formattedFromDate}  To: ${formattedToDate}`,
      '',
    ]);
    heading.getCell(9).alignment = {horizontal: 'center', vertical: 'middle'};
    heading.getCell(9).font = {bold: true, color: {argb: 'ffffff'}, size: 15};
    heading.getCell(9).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: '000000'},
    };
    let currentRowIdx = ws.rowCount;
    ws.mergeCells(currentRowIdx, 9, currentRowIdx + 2, 15);
    ws.mergeCells(currentRowIdx, 33, currentRowIdx + 2, 34);
    const divisionRow = ws.addRow([`Division: ${division}`]);
    currentRowIdx = ws.rowCount;
    let endColumnIdx = ws.columnCount;
    ws.getRow(currentRowIdx).font = {bold: true};
    ws.mergeCells(currentRowIdx, 1, currentRowIdx, endColumnIdx);
    divisionRow.getCell(1).alignment = {horizontal: 'left'};
    ws.addRow([]);
    ws.addRow(headers);
    currentRowIdx = ws.rowCount;
    ws.getRow(currentRowIdx).font = {bold: true};

    ws.getRow(currentRowIdx).font = {bold: true};
    arrayOfArraysData.forEach((rowData: any) => ws.addRow(rowData));
    ws.addRow(totalRow);
    const totalRowIdx = ws.rowCount;
    ws.getRow(totalRowIdx).font = {bold: true};
    fitToColumnExcelJsAoA(ws, arrayOfArraysData, headers, 7, currentRowIdx);
    wb.xlsx
      .writeBuffer()
      .then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileName = 'Expense Bifurcation';
        saveAs(blob, fileName);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const generatePDF = (ExpenseBifurcationReport: any) => {
    const doc = new jsPDF('l', 'mm', 'a4');
    const marginHeight = 30;
    const lineSpacing = 2;
    const fromDate = new Date(expenseDAMasterStartDates);
    const toDate = new Date(expenseDAMasterEndDates);
    const formattedFromDate = moment.utc(fromDate).local().format('DD/MM/YYYY');
    const formattedToDate = moment.utc(toDate).local().format('DD/MM/YYYY');
    const options = {
      margin: {top: marginHeight, left: 1},
      didDrawPage: () => {
        doc.text('Expense Bifurcation', 120, 12);
        doc.setFontSize(11);
        doc.text('Division: ' + division, 10, 20 + lineSpacing);
        doc.setFontSize(8);
        doc.text('From: ' + formattedFromDate, 220, 20 + lineSpacing);
        doc.setFontSize(8);
        doc.text('To: ' + formattedToDate, 250, 20 + lineSpacing);
        doc.setFontSize(8);
      },
      createdRow: (row: any) => {
        row.styles.lineWidth = 0.5;
      },
      drawCell: () => {
        doc.setFontSize(5);
      },
      startY: marginHeight,
    };
    const columnWidths = headers.map((_, colIndex) => {
      const maxContentWidth = Math.max(
        doc.getStringUnitWidth(headers[colIndex]) * 1.5,
        ...(tableData?.items || []).map((row: any[]) => {
          const cellContent = String(row[colIndex]);
          return doc.getStringUnitWidth(cellContent) * 4;
        }),
      );
      return maxContentWidth;
    });

    const columnStyles = columnWidths.reduce((acc: any, width, colIndex) => {
      acc[colIndex] = {cellWidth: width > 16 ? 8.6 : 9};
      return acc;
    }, {});
    (doc as any).autoTable({
      head: [headers],
      body: arrayOfArraysDataWithTotal,
      theme: 'grid',
      headStyles: {
        lineWidth: 0.5,
        lineColor: [0, 0, 0, 0.1],
        fillColor: [255, 255, 255],
        textColor: [1, 1, 1, 1],
        fontSize: 5,
        fontStyle: 'normal',
        fontWeight: 'bold',
        halign: 'center',
        overflow: 'linebreak',
        columnWidth: 'wrap',
        overflowColumns: 'linebreak',
      },
      styles: {
        fontStyle: 'normal',
        fontSize: 5,
        halign: 'center',
        textColor: [1, 1, 1, 1],
        overflow: 'linebreak',
        columnWidth: 'wrap',
        overflowColumns: 'linebreak',
      },
      willDrawCell: (row: any) => {
        doc.setTextColor(60, 60, 60);
        if (row.column.index === 33 || row.row.raw[7] === 'Total') {
          doc.setTextColor(0, 0, 0);
          doc.setFontSize(5.5);
          row.cell.styles.fontStyle = 'bold';
        }
        if (row.section == 'head') {
          doc.setTextColor(0, 0, 0);
          doc.setFontSize(5);
          row.cell.styles.fontStyle = 'bold';
        }
        if (parentHeader.includes(row.cell.raw)) {
          doc.setFillColor('#FFFF00');
          doc.setTextColor(1, 1, 1, 1);
        }
      },
      columnStyles,
      ...options,
    });
    const pageCount = (doc as any).internal.getNumberOfPages();
    const date = new Date();
    const minutes = date.getMinutes();
    const dateStr = `${date.toLocaleDateString()} ${date.getHours()}:${
      minutes < 10 ? `0${minutes}` : minutes
    }`;
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(8);
      doc.text(dateStr, 14, doc.internal.pageSize.height - 10);
      doc.text(
        `Page ${i} of ${pageCount}`,
        264,
        doc.internal.pageSize.height - 10,
      );
    }
    doc.save('expenseBifurcation.pdf');
  };
  const debouncedStaffSearch = (criteria: string | any[]) => {
    if (filteredData?.divisionId && criteria?.length > 0) {
      dispatch(
        fetchStaffDataCreator({
          searchData: criteria,
          divisionIds: [filteredData?.divisionId],
          IncludeInActive: true,
        }),
      );
    }
  };
  const renderInputDAMasterAdd = useCallback(function (params: any) {
    return (
      <TextField
        size="small"
        {...params}
        sx={DatePickerStyle.datepick}
        helperText={null}
        onKeyDown={preventDefaultHandleDAMasterAdd}
        style={{maxwidth: 270, marginLeft: 5}}
      />
    );
  }, []);

  const handleChangeDAMasterStartDates = useCallback(
    (value: any) => {
      setExpenseDAMasterStartDates(value);
      setExpenseDAMasterMaxDate(
        new Date(
          new Date(value)?.getFullYear() + 1,
          new Date(value)?.getMonth(),
          new Date(value)?.getDate(),
        ).toString(),
      );
    },
    [expenseDAMasterStartDates],
  );

  const handleOnClickGo = () => {
    dispatch(
      fetchExpenseReportBICreator({
        divisionId: filteredData?.divisionId,
        startDate: `${new Date(expenseDAMasterStartDates)?.toLocaleDateString(
          'en-ca',
        )}T00:00:00`,
        endDate: `${new Date(expenseDAMasterEndDates)?.toLocaleDateString(
          'en-ca',
        )}T23:59:59`,
        staffUserIds: selectedStaffData?.filter((element: any) =>
          Boolean(element),
        ),
      }),
    );
    setOpen(true);
  };

  const validation = () => {
    if (
      filteredData?.divisionId?.length !== 0 &&
      expenseDAMasterStartDates &&
      expenseDAMasterEndDates
    ) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  };

  useEffect(() => {
    validation();
  }, [
    filteredData?.divisionId,
    selectedStaffData,
    expenseDAMasterStartDates,
    expenseDAMasterEndDates,
  ]);

  useEffect(() => {
    dispatch(fetchDivisionDataCreator());
    return () => {
      dispatch(expenseReportsActions.resetFiltersBI());
      dispatch(expenseReportsActions?.setSelectedStaffData([]));
    };
  }, []);

  const editingStaffHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e === undefined || e === null) return;
    if (e?.target?.value?.length >= 3) debouncedStaffSearch(e?.target?.value);
  };
  const prevSelectedStaffval = (prevSelectedStaff: any, event: any) =>
    prevSelectedStaff.filter((staff: {userId: any}) =>
      event.target.value.includes(staff.userId),
    );
  const condi = (updatedSelectedStaff: any, staff: any) =>
    !updatedSelectedStaff.some(
      (s: {userId: number}) => s.userId === staff.userId,
    );
  const onStaffChange = (event: any) => {
    setCurrentStaff(prevSelectedStaff => {
      // Remove deselected staff codes
      const updatedSelectedStaff = prevSelectedStaffval(
        prevSelectedStaff,
        event,
      );

      // Add newly selected staff codes
      staffDatas.forEach(staff => {
        if (
          event.target.value.includes(staff.userId) &&
          condi(updatedSelectedStaff, staff)
        ) {
          updatedSelectedStaff.push(staff);
        }
      });

      return updatedSelectedStaff;
    });
    dispatch(expenseReportsActions?.setSelectedStaffData(event.target.value));
  };
  utilBifurcate.handleChangeDAMasterStartDates = handleChangeDAMasterStartDates;
  utilBifurcate.handleChangeDAMasterEndDates = handleChangeDAMasterEndDates;
  utilBifurcate.debouncedStaffSearch = debouncedStaffSearch;
  utilBifurcate.editStaffHandler = editingStaffHandler;
  utilBifurcate.handleChangeDivisionId = handleChangeDivisionId;
  utilBifurcate.downloadExcel = downloadExcel;
  utilBifurcate.onStaffChange = onStaffChange;
  utilBifurcate.generatePDF = generatePDF;
  utilBifurcate.preventDefaultHandleDAMasterAdd =
    preventDefaultHandleDAMasterAdd;
  utilBifurcate.handleOnClickGo = handleOnClickGo;
  return (
    <>
      <Stack
        spacing={0.5}
        p={2}
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        style={{
          height: 120,
          borderRadius: 6.7,
          margin: 5,
        }}>
        <Grid container spacing={0.5} sx={{m: 0.5}}>
          <Grid item xs={2.5}>
            <Box>
              <Box sx={{marginLeft: 1}}>
                <Typography className={classes.label}>{strings.ModuleSpecificUtilitie.expenseReportPage.Division}</Typography>
              </Box>
              <Item className={classes.marTop}>
                <FormControl required fullWidth size="small">
                  <InputLabel id="division-dropdown">{strings.ModuleSpecificUtilitie.expenseReportPage.Division}</InputLabel>
                  <Select
                    inputProps={{
                      'data-testid': 'dropdown-division',
                    }}
                    labelId="division-dropdown"
                    size="small"
                    value={filteredData?.divisionId}
                    title={
                      divisionData.find(
                        (divisionObj: any) =>
                          divisionObj.value === filteredData?.divisionId,
                      )?.label || ''
                    }
                    label="Division*"
                    MenuProps={MenuProp}
                    onChange={(event: any) =>
                      handleChangeDivisionId(event?.target?.value, 'divisionId')
                    }>
                    {divisionData?.map((divisionValue: any) => {
                      return (
                        <MenuItem
                          value={divisionValue?.value}
                          key={divisionValue?.value}>
                          {divisionValue?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Item>
            </Box>
          </Grid>
          <Grid item xs={2.5}>
            <Box className={classes.marTop}>
              <Box sx={{marginLeft: 1}}>
                <Typography className={classes.label}>{strings.ModuleSpecificUtilitie.From}</Typography>
              </Box>
              <Item className={classes.marTop}>
                <FormControl sx={{marginTop: '1px', minWidth: '100px'}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      mask="__/__/____"
                      inputFormat="DD/MM/YYYY"
                      disabled={!filteredData?.divisionId}
                      value={expenseDAMasterStartDates}
                      maxDate={expenseDAMasterEndDates}
                      onChange={handleChangeDAMasterStartDates}
                      label="Start Date *"
                      PopperProps={DialogProps}
                      renderInput={renderInputDAMasterAdd}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Item>
            </Box>
          </Grid>
          <Grid item xs={2.5}>
            <Box sx={{minWidth: 120}}>
              <Box sx={{marginLeft: 1}}>
                <Typography className={classes.label}>{strings.ModuleSpecificUtilitie.To}</Typography>
              </Box>
              <Item className={classes.marTop}>
                <FormControl sx={{marginTop: '1px', minWidth: '150px'}}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      mask="__/__/____"
                      inputFormat="DD/MM/YYYY"
                      disabled={expenseDAMasterStartDates == null}
                      maxDate={new Date(expenseDAMasterMaxDate)}
                      minDate={expenseDAMasterStartDates}
                      value={expenseDAMasterEndDates}
                      onChange={handleChangeDAMasterEndDates}
                      label="End Date *"
                      PopperProps={DialogProps}
                      renderInput={renderInputDAMasterAdd}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Item>
            </Box>
          </Grid>
          <Grid item xs={2.5} spacing={2}>
            <Box>
              <Box sx={{marginLeft: 1.5}}>
                <Typography className={classes.label}>{strings.ModuleSpecificUtilitie.expenseReportPage.Staff}</Typography>
              </Box>
              <Item className={classes.marTop}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-State">{strings.ModuleSpecificUtilitie.expenseReportPage.Staff}</InputLabel>
                  <Select
                    inputProps={{
                      'data-testid': 'dropdown-staff',
                    }}
                    labelId="demo-simple-State"
                    id="demo-simple-State"
                    disabled={!filteredData?.divisionId}
                    label="State"
                    size="small"
                    multiple
                    MenuProps={MenuProp}
                    value={selectedStaffData || []}
                    title={currentStaff
                      .filter((value: any) =>
                        selectedStaffData?.filter(
                          (id: any) => id === value?.employeeCode,
                        ),
                      )
                      .map(
                        (value: any) =>
                          `${value?.fullName}-${value?.employeeCode}`,
                      )
                      .join(', ')}
                    onChange={onStaffChange}
                    renderValue={(selected: any) => {
                      return (
                        <div
                        style={style.change}>
                          {' '}
                          {currentStaff.map(
                            (staff: any) =>
                              selected?.some(
                                (id: any) => id == staff?.userId,
                              ) &&
                              `${staff?.fullName}-${staff?.employeeCode}${
                                selected.indexOf(staff?.userId) ===
                                selected.length - 1
                                  ? ''
                                  : ', '
                              }`,
                          )}
                        </div>
                      );
                    }}>
                    <TextField
                      size="small"
                      style={{width: '100%'}}
                      autoFocus
                      placeholder="Type to search..."
                      InputProps={{
                        style: {
                          height: 30,
                          fontSize: 11,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={editingStaffHandler}
                      onKeyDown={e => {
                        if (e.key !== 'Escape') {
                          e.stopPropagation();
                        }
                      }}
                    />
                    {currentStaff?.map((staff: any) => (
                      <MenuItem key={staff?.userId} value={staff?.userId}>
                        <Checkbox
                          checked={selectedStaffData?.some(
                            (o: any) => o == staff?.userId,
                          )}
                        />
                        {`${staff?.fullName}-${staff?.employeeCode}`}
                      </MenuItem>
                    ))}
                    {staffDatas
                      ?.filter(
                        (staff: any) =>
                          !selectedStaffData.includes(staff.userId),
                      )
                      ?.map((staff: any) => (
                        <MenuItem key={staff?.userId} value={staff?.userId}>
                          <Checkbox
                            checked={selectedStaffData?.some(
                              (o: any) => o == staff?.userId,
                            )}
                          />
                          {`${staff?.fullName}-${staff?.employeeCode}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Item>
            </Box>
          </Grid>
          <Grid item xs={0.5}>
            <Item className={classes.marTop}>
              <DebouncedButton
                data-testid="button-handleClickGo"
                variant="contained"
                disabled={validated}
                size="large"
                sx={{top: 18, width: 53.3}}
                onClick={handleOnClickGo}>
                {strings.Go}
              </DebouncedButton>
            </Item>
          </Grid>
        </Grid>
      </Stack>
      {open && tableData?.items?.length > 0 && (
        <Box>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              height: 30,
              marginBottom: -10,
              marginTop: 20,
              marginRight: 20,
            }}>
            <Button
              data-testid="downloadpdf-button"
              variant="contained"
              sx={{marginLeft: 65}}
              onClick={() => {
                generatePDF(ExpenseBifurcationReport);
              }}>
              {strings.ModuleSpecificUtilitie.DownloadPdf}
            </Button>
            <Button
              data-testid="downloadBills-button"
              variant="contained"
              sx={{marginLeft: 2}}
              onClick={downloadExcel}>
              {strings.ModuleSpecificUtilitie.ExportToExcel}
            </Button>
          </div>
        </Box>
      )}
      {open && <ExpenseBifurcationReport />}
    </>
  );
};
export default BifurcationHeader;
