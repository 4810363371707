import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { routeSelector } from '@app/router/redux';
import { makeStyles } from '@mui/styles';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { approveExpenseStateSelector } from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import { approveExpenseStateActions } from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import { useCallback } from 'react';
import { SEARCH_OBJECT } from '@app/screens/module-specific-utilities/module-specific-utilities.types';
import { fetchExpenseApprovalsDataCreator } from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import { getPreviousYearMonths } from '@app/screens/module-specific-utilities/components/expense-reports/TA-summary/TASummaryReportHeader';
import theme from '@app/themes';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
  },
  btn: {
    border: 'none',
    '&:focus': {
      border: 'none',
    },
  },
});

export const ApproveExpensesNavBar = () => {
  const dispatch = useDispatch();

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading()
  );
  const screenDetails = useSelector(
    approveExpenseStateSelector.getApproveExpenseScreen()
  );
  const classes = useStyles();
  const filteredData = useSelector(
    approveExpenseStateSelector?.getFilterData(),
  );
  const statusData = useSelector(approveExpenseStateSelector?.getStatusData());
  const selectedManagerData: SEARCH_OBJECT = useSelector(
    approveExpenseStateSelector?.getSelectedManagerData(),
  );
  const selectedStaffData: SEARCH_OBJECT = useSelector(
    approveExpenseStateSelector?.getSelectedStaffData(),
  );
  const monthYearData = [{monthDesc: 'Select All', year: 0, month: 0}].concat(getPreviousYearMonths());
  const handleBack = useCallback(() =>{
    dispatch(
      approveExpenseStateActions.setApproveExpenseScreen({
        screen: 1,
        backBtn: false,
      })
    );
    dispatch(approveExpenseStateActions.setConfirmExpenseApprovalsReset());
    dispatch(approveExpenseStateActions?.setExpenseApprovalsData([]));
    dispatch(approveExpenseStateActions?.setSelectedExpenseApprovalsData([]));
    dispatch(approveExpenseStateActions.setApprovedStaffDetails(''));
    dispatch(
      approveExpenseStateActions?.setIsExpenseAprrovalsAllSelected(false),
    );    
    dispatch(approveExpenseStateActions.setDailyEditedRowColorIndex([]));
    dispatch(approveExpenseStateActions.setDailyEditedRowColorIndexArr1([]));
    dispatch(approveExpenseStateActions.setMiscEditedRowColorIndex([]));
    dispatch(approveExpenseStateActions?.setSelectedExpenseApprovalsUser({}));
    dispatch(approveExpenseStateActions.setRejectExpenseApprovalsReset());
    dispatch(approveExpenseStateActions.setRejectExpenseModalCheckbox(false));
    const statusVal=()=>{
      return statusData
      .filter((statusData: any) =>
        filteredData?.statusId?.includes(statusData?.displayName),
      )
      ?.map((status: any) => status.id)
    }
    const dropdownValues = {
      divisionIds: filteredData?.divisionId,
      year: filteredData?.year,
      statusIds: statusVal(),
      months: monthYearData?.map((ele: any) => {
        if( filteredData?.monthYearData?.includes(ele.month))
        return {
          month: ele.month,
          year: ele.year,
        };
      }).filter((element: any) => Boolean(element) && element.month !== 0),
      stateIds: filteredData?.stateId ? filteredData?.stateId : [],
      userIds:!selectedManagerData && selectedStaffData?.userId
        ? [selectedStaffData?.userId
        ]
        : selectedStaffData ?? [],
      managerStaffPositionId: selectedManagerData
        ? selectedManagerData?.staffPositionId
        : 0,
        };

    dispatch(fetchExpenseApprovalsDataCreator(dropdownValues));
    dispatch(
      approveExpenseStateActions.setIsExpenseAprrovalsAllSelected(false),
    );
  },[filteredData,selectedStaffData,selectedManagerData]);

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        {screenDetails?.backBtn && (
          <Button variant='text' className={classes.btn} onClick={handleBack}>
            <ArrowBackIosNewOutlinedIcon />
          </Button>
        )}
        <Typography
          variant='h6'
          noWrap
          component='div'
          color={theme.colors.LIGHT_NAVY_BLUE}
          fontWeight={theme.sizing.weight800}
        >
          {navbarComponentHeading}
        </Typography>
      </div>
    </div>
  );
};
