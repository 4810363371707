import {createAction, createSlice} from '@reduxjs/toolkit';

export const intrasistChallanInitialState = {
  isOpenChallans: true,
  intransitChallanloading: false,
  voucherDataLoading: false,
  challanData: [],
  autoSuggestionData: [],
  filteredData: [],
  intransitChallanError: '',
  selectedChallans: [],
  recievedChallans: [],
  searchType: '',
  searchvalue: '',
  totalIntransitChallans: 0,
  divisionLoading: false,
  divisionError: '',
  divisionData: [],
  challanAuditData: [],
  ChallanPage: 0,
  challanAuditReport: [],
  challanReceivesearchData: [],
  challanReceivePayload: {},
  challanReceiveAutoSuggest: [],
  loading: false,
  page: 1,
  reportDataLoading: false,
};

export const intransitChallanDivisionCreator = createAction(
  'Intransit_Challan_Division',
);
export const intransitChallanDivisionTypeName =
  intransitChallanDivisionCreator().type;
export const challanReceiveAutoSuggestCreator = <any>(
  createAction('Challan_Auto_Suggest')
);
export const challanReceiveAutoSuggestTypeName =
  challanReceiveAutoSuggestCreator({}).type;
export const challanAuditDataCreator = createAction<any>('Challan_Audit_Data');
export const challanAuditDataTypeName = challanAuditDataCreator({}).type;
export const challanAuditReportCreator = createAction<any>(
  'Challan_Audit_Report',
);
export const challanAuditReportTypeName = challanAuditReportCreator({}).type;
export const challanReceivesearchDataCreator = createAction<any>(
  'Challan_Receive_Search_Data',
);
export const challanReceivesearchDataTypName = challanReceivesearchDataCreator(
  {},
).type;
export const fetchIntransistChallanCreator = createAction<any>(
  'IN-TRANSIST-CHALLAN',
);

export const inTransitionAutoSuggestCreator =
  createAction<any>('AUTO_SUGGESTION');
export const inTransitFilteredData = createAction<any>('FILTERED_DATA');
export const postOpenCloseChallanCreator = createAction<any>('POST_OPEN_CLOSE');
export const recieveChallansCreator = createAction<any>('RECIEVE_CHALLANS');
export const enableDisableIntransitChallansCreator = createAction<any>(
  'ENABLE-DISABLE_INTRANSIT_CHALLAN',
);
export const fetchVoucherDataCreator = createAction<any>('VOUCHER_NUMBER_DATA');

export const fetchIntransistChallanName = fetchIntransistChallanCreator(
  {},
).type;
export const fetchAutoSuggestion = inTransitionAutoSuggestCreator({}).type;
export const fetchFilteredData = inTransitFilteredData({}).type;
export const postOpenCloseChallanName = postOpenCloseChallanCreator({}).type;
export const enableDisableIntransitChallansName =
  enableDisableIntransitChallansCreator({}).type;
export const fetchVoucherNumberData = fetchVoucherDataCreator({}).type;
export const fetchRecieveChallans = recieveChallansCreator({}).type;

const intransistChallanHandler = createSlice({
  name: 'IN_TRANSIST-CHALLAN_HANDLER',
  initialState: intrasistChallanInitialState,
  reducers: {
    setDivisionData: (prevState, {payload}) => {
      return {
        ...prevState,
        divisionData: payload?.divisionData,
        divisionLoading: payload?.divisionLoading,
        divisionError: payload?.divisionError,
      };
    },
    setIsOpenChallans: prevState => {
      return {
        ...prevState,
        isOpenChallans: !prevState.isOpenChallans,
        selectedChallans: [],
      };
    },
    setIntransistChallanInit: prevState => {
      return {
        ...prevState,
        intransitChallanloading: true,
      };
    },
    setIntransistChallansSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        challanData: payload,
        selectedChallans: [],
        intransitChallanloading: false,
      };
    },
    setIntransistChallanError: (prevState, {payload}) => {
      return {
        ...prevState,
        intransitChallanloading: false,
        intransitChallanError: payload,
      };
    },
    setAutoSuggestionsData: prevState => {
      return {
        ...prevState,
        autoSuggestionData: [],
      };
    },
    setAutoSuggestionDataSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        autoSuggestionData: payload,
      };
    },
    setInTransitFilteredData: prevState => {
      return {
        ...prevState,
        filteredData: [],
      };
    },
    setFilteredDataSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        filteredData: payload,
        intransitChallanloading: false,
      };
    },
    setTotalIntransitChallans: (prevState, {payload}) => {
      return {
        ...prevState,
        totalIntransitChallans: payload,
      };
    },
    setSelectedIntransitChallans: (prevState, {payload}) => {
      return {
        ...prevState,
        selectedChallans: payload,
      };
    },
    setVoucherNumberData: (prevState, {payload}) => {
      return {
        ...prevState,
        voucherNumber: payload,
        voucherDataLoading: false,
      };
    },
    setRecievedChallansData: (prevState, {payload}) => {
      return {
        ...prevState,
        recievedChallans: payload,
        intransitChallanloading: false,
      };
    },
    setSearchTypeAndSearchValue: (prevState, {payload}) => {
      return {
        ...prevState,
        searchType: payload.searchType,
        searchValue: payload.searchValue,
      };
    },
    setChallanAuditData: (prevState, {payload}) => {
      return {
        ...prevState,
        challanAuditData: payload,
      };
    },
    setChallanPage: (prevState, {payload}) => {
      return {
        ...prevState,
        ChallanPage: payload,
      };
    },
    setChallanAuditReport: (prevState, {payload}) => {
      return {
        ...prevState,
        challanAuditReport: payload,
      };
    },
    setChallanReceivesearchData: (prevState, {payload}) => {
      return {
        ...prevState,
        challanReceivesearchData: payload,
      };
    },
    setChallanReceivePayload: (prevState, {payload}) => {
      return {
        ...prevState,
        challanReceivePayload: payload,
      };
    },
    setChallanReceiveAutoSuggest: (prevState, {payload}) => {
      return {
        ...prevState,
        challanReceiveAutoSuggest: payload,
      };
    },
    setLoading: (prevState, {payload}) => {
      return {
        ...prevState,
        loading: payload,
      };
    },
    setPage: (prevState, {payload}) => {
      return {
        ...prevState,
        page: payload,
      };
    },
    setReportLoader: (prevState, {payload}) => {
      return {
        ...prevState,
        reportDataLoading: payload,
      }
    }
  },
});

export const intransistChallanActions = intransistChallanHandler.actions;
export const intransistChallanReducer = intransistChallanHandler.reducer;
