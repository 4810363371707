export const leftmenu = [
  {
    userId: 0,
    isWriteAccess: true,
    division: [
      {
        id: 0,
        name: 'string',
        shortName: 'string',
        maxPatchCount: 0,
        kycPartyLimit: 0,
      },
    ],
    state: [
      {
        id: 0,
        name: 'string',
      },
    ],
    leftMenu: [
      {
        id: 0,
        parentId: 0,
        menuText: 'Access Management',
        displayOrder: 0,
        webPath: '/access-management',
        webComponent: 'AccessManagement',
        mobilePath: 'string',
        mobileComponent: 'string',
        apiUrl: 'string',
        apiGetParameters: 0,
        firstLevel: [
          {
            id: 0,
            parentId: 0,
            menuText: 'User Management',
            displayOrder: 0,
            webPath: '/user-management',
            webComponent: 'UserManagement',
            mobilePath: 'string',
            mobileComponent: 'string',
            apiUrl: 'string',
            apiGetParameters: 0,
            secondLevel: [],
          },
          {
            id: 0,
            parentId: 0,
            menuText: 'Access Group Master',
            displayOrder: 0,
            webPath: '/access-Group-master',
            webComponent: 'AccessGroupMaster',
            mobilePath: 'string',
            mobileComponent: 'string',
            apiUrl: 'string',
            apiGetParameters: 0,
            secondLevel: [],
          },
        ],
      },
      {
        id: 0,
        parentId: 0,
        menuText: 'Module Specific Utilities',
        displayOrder: 0,
        webPath: '/module-specific-utilities',
        webComponent: 'ModuleSpecificUtilities',
        mobilePath: 'string',
        mobileComponent: 'string',
        apiUrl: 'string',
        apiGetParameters: 0,
        firstLevel: [
          {
            id: 0,
            parentId: 0,
            menuText: 'Plan & Meet Status',
            displayOrder: 0,
            webPath: '/module-specific-utilities/plan-and-meet',
            webComponent: 'PlanAndMeet',
            mobilePath: 'string',
            mobileComponent: 'string',
            apiUrl: 'string',
            apiGetParameters: 0,
            secondLevel: [],
          },
        ],
      },
      {
        id: 0,
        parentId: 0,
        menuText: 'Master Data Management',
        displayOrder: 0,
        webPath: '/master-data-management',
        webComponent: 'MasterDataManagement',
        mobilePath: 'string',
        mobileComponent: 'string',
        apiUrl: 'string',
        apiGetParameters: 0,
        firstLevel: [
          {
            id: 0,
            parentId: 0,
            menuText: 'Data Correction',
            displayOrder: 0,
            webPath: '/master-data-management/data-correction',
            webComponent: 'DataCorrection',
            mobilePath: 'string',
            mobileComponent: 'string',
            apiUrl: 'string',
            apiGetParameters: 0,
            secondLevel: [],
          },
        ],
      },
    ],
  },
];
export const mockFilterHeadQuarters = [
  {
    id: 0,
    value: 'Jaipur',
    count: 0,
    parentId: 30,
    isDefault: true,
    isChecked: true,
  },
  {
    id: 11,
    value: 'Jodhpur',
    count: 0,
    parentId: 30,
    isDefault: true,
    isChecked: true,
  },
  {
    id: 1,
    value: 'Gurugram',
    count: 0,
    parentId: 30,
    isDefault: false,
    isChecked: false,
  },
  {
    id: 2,
    value: 'Delhi',
    count: 0,
    parentId: 30,
    isDefault: true,
    isChecked: true,
  },
  {
    id: 3,
    value: 'Hyderabad',
    count: 0,
    parentId: 30,
    isDefault: false,
    isChecked: false,
  },
  {
    id: 4,
    value: 'Chennai',
    count: 0,
    parentId: 30,
    isDefault: false,
    isChecked: false,
  },
  {
    id: 5,
    value: 'Bengluru',
    count: 0,
    parentId: 30,
    isDefault: false,
    isChecked: false,
  },
  {
    id: 6,
    value: 'Goa',
    count: 0,
    parentId: 30,
    isDefault: false,
    isChecked: false,
  },
  {
    id: 7,
    value: 'Indore',
    count: 0,
    parentId: 30,
    isDefault: false,
    isChecked: false,
  },
  {
    id: 8,
    value: 'Shimla',
    count: 0,
    parentId: 30,
    isDefault: false,
    isChecked: false,
  },
];

export const mockFilterHeadQuarters2 = [
  {
    id: 9,
    value: 'Noida',
    count: 0,
    parentId: 31,
    isDefault: false,
    isChecked: false,
  },
  {
    id: 10,
    value: 'Udaipur',
    count: 0,
    parentId: 31,
    isDefault: false,
    isChecked: false,
  },
];

export const mockFilterHeadQuarters3 = [
  {
    id: 9,
    value: 'Noida',
    count: 0,
    parentId: 36,
    isDefault: false,
    isChecked: false,
  },
];

export const userInfo = [
  {
    id: 1,
    userId: 11883,
    isActive: false,
    employeeId: 11883,
    name: 'testadmin2 serp',
    role: 'Admin',
    accessType: 'Read Only',
    division: [
      {
        id: 5,
        name: 'MAGNET',
        shortName: 'MAG',
        maxPatchCount: null,
        kycPartyLimit: null,
      },
      {
        id: 6,
        name: 'MANKIND',
        shortName: 'MNK',
        maxPatchCount: null,
        kycPartyLimit: null,
      },
    ],
    state: [
      {
        id: 5388,
        name: 'KARNATAKA',
      },
      {
        id: 5380,
        name: 'NEPAL',
      },
    ],
    accessGroup: [
      {
        accessGroupName: 'AccessManagement',
        id: 1,
      },
      {
        accessGroupName: 'Group 2',
        id: 2,
      },
      {
        accessGroupName: 'Group 4',
        id: 4,
      },
      {
        accessGroupName: 'Group 3',
        id: 3,
      },
    ],
  },
];

export const planAndMeetListData = [
  {
    id: 1,
    empId: 1,
    staffPositionId: 125,
    empName: 'Puneet',
    roleId: 2,
    divisionId: 1,
    divisionName: 'asfsafsa',
    managerName: 'asfda',
    stpStatusId: 1,
    isSTPLocked: true,
    mtpStatusId: 1,
    isMTPLocked: false,
    dcrStatus: true,
    isDCRLocked: false,
    isSTPEditable: true,
    isMTPEditable: true,
    isDCREditable: true,
    mtpSortOrder: 2,
    hasMTPRecommendation: true,
    hierarchyList: [
      {
        id: 1,
        empId: 1,
        staffPositionId: 124,
        empName: 'Puneet',
        roleId: 3,
        divisionId: 1,
        divisionName: 'asfsafsa',
        managerName: 'asfda',
        stpStatusId: 1,
        isSTPLocked: true,
        mtpStatusId: 1,
        isMTPLocked: false,
        dcrStatus: true,
        isDCRLocked: false,
        isSTPEditable: true,
        isMTPEditable: true,
        isDCREditable: true,
        mtpSortOrder: 2,
        hasMTPRecommendation: true,
        hierarchyList: [
          {
            id: 2,
            empId: 1,
            staffPositionId: 1234,
            empName: 'Puneet',
            roleId: 4,
            divisionId: 1,
            divisionName: 'asfsafsa',
            managerName: 'asfda',
            stpStatusId: 1,
            isSTPLocked: false,
            mtpStatusId: 3,
            isMTPLocked: true,
            dcrStatus: true,
            isDCRLocked: true,
            isSTPEditable: true,
            isMTPEditable: false,
            isDCREditable: true,
            mtpSortOrder: 2,
            hasMTPRecommendation: false,
          },
          {
            id: 2,
            empId: 1,
            staffPositionId: 1234,
            empName: 'Puneet',
            roleId: 4,
            divisionId: 1,
            divisionName: 'asfsafsa',
            managerName: 'asfda',
            stpStatusId: 1,
            isSTPLocked: false,
            mtpStatusId: 3,
            isMTPLocked: true,
            dcrStatus: false,
            isDCRLocked: true,
            isSTPEditable: true,
            isMTPEditable: false,
            isDCREditable: true,
            mtpSortOrder: 2,
            hasMTPRecommendation: false,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    empId: 1,
    staffPositionId: 12,
    empName: 'Puneet',
    roleId: 4,
    divisionId: 1,
    divisionName: 'asfsafsa',
    managerName: 'asfda',
    stpStatusId: 1,
    isSTPLocked: false,
    mtpStatusId: 4,
    isMTPLocked: false,
    dcrStatus: true,
    isDCRLocked: true,
    isSTPEditable: true,
    isMTPEditable: false,
    isDCREditable: true,
    isGspYearlyLocked: true,
    mtpSortOrder: 2,
    hasMTPRecommendation: false,
    stpUnlockEndDate: '2022-07-13T14:18:11',
    mtpUnlockEndDate: '2022-07-13T14:18:11',
  },
  {
    id: 2,
    empId: 1,
    staffPositionId: 11,
    empName: 'Puneet',
    roleId: 4,
    divisionId: 1,
    divisionName: 'asfsafsa',
    managerName: 'asfda',
    stpStatusId: 1,
    isSTPLocked: true,
    mtpStatusId: 3,
    isMTPLocked: true,
    dcrStatus: false,
    isDCRLocked: true,
    isSTPEditable: true,
    isMTPEditable: false,
    isDCREditable: true,
    isGspYearlyLocked: false,
    mtpSortOrder: 2,
    hasMTPRecommendation: false,
    // stpUnlockEndDate: '2022-07-13T14:18:11',
    mtpUnlockEndDate: '2022-07-13T14:18:11',
  },
  {
    id: 1,
    empId: 1,
    staffPositionId: 1224,
    empName: 'Puneet',
    roleId: 3,
    divisionId: 1,
    divisionName: 'asfsafsa',
    managerName: 'asfda',
    stpStatusId: 1,
    isSTPLocked: false,
    mtpStatusId: 1,
    isMTPLocked: false,
    dcrStatus: false,
    isDCRLocked: false,
    isSTPEditable: true,
    isMTPEditable: true,
    isDCREditable: true,
    isGspYearlyLocked: false,
    mtpSortOrder: 2,
    hasMTPRecommendation: true,
  },
];

export const recommendationsMock = [
  {
    givenBy: {
      firstName: 'Sonu',
      middleName: 'Kumar',
      lastName: 'Shah',
      roleId: 2,
    },
    isMinApproval: true,
    isApproved: true,
    messages: ['test message 1', 'test message 2'],
  },
  {
    givenBy: {
      firstName: 'SYED',
      middleName: null,
      lastName: 'SUNEERUDDIN',
      roleId: 3,
    },
    isMinApproval: false,
    isApproved: true,
    messages: ['test message'],
  },
];

export const planAndMeetFilterMock = {
  dcrStatus: {
    isDefault: false,
    filterData: [
      {
        id: 1,
        value: 'enable',
        count: 80,
        parentId: 0,
        isDefault: false,
      },
      {
        id: 2,
        value: 'disable',
        count: 80,
        parentId: 0,
        isDefault: false,
      },
    ],
  },
  stpStatus: {
    isDefault: true,
    isChecked: true,
    filterData: [
      {
        id: 0,
        value: 'No Record',
        count: 20,
        parentId: 0,
        isDefault: false,
      },
      {
        id: 1,
        value: 'Submitted',
        count: 20,
        parentId: 0,
        isDefault: true,
      },
    ],
  },
  mtpStatus: {
    isDefault: false,
    filterData: [
      {
        id: 0,
        value: 'Approved',
        count: 20,
        parentId: 0,
        isDefault: false,
      },
      {
        id: 1,
        value: 'Work in progress',
        count: 20,
        parentId: 0,
        isDefault: false,
      },
    ],
  },
  lockUnlockStatus: {
    isDefault: true,
    filterData: [
      {
        id: 0,
        value: 'Locked',
        count: 20,
        parentId: 0,
        isDefault: false,
      },
      {
        id: 1,
        value: 'Unlocked',
        count: 0,
        parentId: 0,
        isDefault: false,
      },
    ],
  },
  zones: {
    isDefault: false,
    isChecked: true,
    filterData: [
      {
        id: 101,
        value: 'North',
        count: 0,
        parentId: 0,
        isChecked: true,
        isDefault: false,
        regions: [
          {
            id: 30,
            value: 'Region 1',
            count: 0,
            parentId: 101,
            isDefault: false,
            isChecked: true,
            headquarters: mockFilterHeadQuarters,
          },
          {
            id: 31,
            value: 'Region 2',
            count: 0,
            parentId: 101,
            isDefault: false,
            headquarters: mockFilterHeadQuarters2,
          },
          {
            id: 32,
            value: 'Region 3',
            count: 0,
            parentId: 101,
            isDefault: false,
          },
        ],
      },
      {
        id: 102,
        value: 'South',
        count: 0,
        parentId: 0,
        isDefault: false,
        regions: [
          {
            id: 36,
            value: 'Region 3',
            count: 0,
            parentId: 102,
            isDefault: false,
            headquarters: mockFilterHeadQuarters3,
          },
        ],
      },
    ],
  },
  regions: {
    isDefault: false,
    filterData: [
      {
        id: 30,
        value: 'Region 1',
        count: 0,
        parentId: 101,
        isDefault: false,
      },
      {
        id: 31,
        value: 'Region 2',
        count: 0,
        parentId: 101,
        isDefault: false,
      },
      {
        id: 36,
        value: 'Region 3',
        count: 0,
        parentId: 102,
        isDefault: false,
      },
    ],
  },
  headquarters: {
    isDefault: false,
    filterData: mockFilterHeadQuarters,
  },
  divisions: {
    isDefault: false,
    filterData: [
      {
        id: 0,
        value: 'UP',
        count: 20,
        parentId: 0,
        isDefault: false,
      },
      {
        id: 2,
        value: 'UPP',
        count: 20,
        parentId: 0,
        isDefault: false,
      },
      {
        id: 3,
        value: 'UPPP',
        count: 20,
        parentId: 0,
        isDefault: false,
      },
    ],
  },
};

export const preferredFiltersMock = {
  dcrStatus: [false, true],
  stpStatus: [1, 2],
  mtpStatus: [0, 4, 6],
  lockUnlockStatus: [true, true],
  zones: [101, 102],
  regions: [30, 31, 36],
  headQuarters: [
    {
      id: 0,
      value: 'Name 1',
      parentId: 30,
    },
    {
      id: 1,
      value: 'Name 2',
      parentId: 30,
    },
    {
      id: 2,
      value: 'Name 3',
      parentId: 36,
    },
  ],
  divisions: [18, 47],
};

export const mockSTPEdit = {
  statusId: 2,
  standardTourPlanId: 247,
  isLocked: true,
};

export const mockMTPEdit = {
  statusId: 3,
  id: 123,
};
export const mockGspUnlockResponse = {
  success: true,
  empName: 'abc',
};
export const mockMtpUnlockReason = [
  {
    id: 13,
    reason: 'Wrong detail filled',
    isFineApplicable: true,
  },
  {
    id: 14,
    reason: 'MTP not submit on time',
    isFineApplicable: true,
  },
  {
    id: 15,
    reason: 'Other',
    isFineApplicable: false,
  },
  {
    id: 16,
    reason: 'Due to technical issue (incomplete MTP)',
    isFineApplicable: false,
  },
  {
    id: 17,
    reason:
      'On Leave - (due to family problem / function/marriage/accident/sickness)',
    isFineApplicable: false,
  },
  {
    id: 18,
    reason: 'Due to network issue / server problem',
    isFineApplicable: false,
  },
  {
    id: 19,
    reason: 'Senior manager',
    isFineApplicable: false,
  },
  {
    id: 20,
    reason: 'Challan not received',
    isFineApplicable: false,
  },
  {
    id: 21,
    reason: 'New Joining / Resign',
    isFineApplicable: false,
  },
  {
    id: 22,
    reason: 'Due to flood / curfew',
    isFineApplicable: false,
  },
  {
    id: 23,
    reason:
      'Due to wsfa data not update (customer not showing update l area not assign transfer/promotion etc)',
    isFineApplicable: false,
  },
];

export const mockSTPMTPUnlockResponse = {
  statusId: 1,
  standardTourPlanId: 97,
  isLocked: false,
};

export const mockDCRUnlockRequestData = [
  {
    requestId: 3,
    id: '64341',
    staffPositionId: 64341,
    employeeId: '10001748',
    employeeName: 'Suresh SINGH BASERA',
    unlockDate: '2021-12-01T00:00:00',
    raisedOn: '2021-12-18T00:00:00',
    approvedDate: null,
    unlockType: 1,
    unlockReason: 'test',
    status: 'Pending FLM Approval',
  },
  {
    requestId: 1,
    id: '64340',
    staffPositionId: 64340,
    employeeId: '10001749',
    employeeName: 'MAHESH SINGH BASERA',
    unlockDate: '2021-12-01T00:00:00',
    raisedOn: '2021-12-18T00:00:00',
    approvedDate: null,
    unlockType: 2,
    unlockReason: 'test',
    status: 'Pending FLM Approval',
  },
  {
    requestId: 2,
    id: '47877',
    staffPositionId: 47877,
    employeeId: '10002397',
    employeeName: 'ASHISH KUMAR SHARMA',
    unlockDate: '2021-12-01T00:00:00',
    raisedOn: '2021-12-21T00:00:00',
    approvedDate: null,
    unlockType: 2,
    unlockReason: 'Unlock ',
    status: 'Pending FLM Approval',
  },
];

export const approveDCRResponseMock = {
  status: true,
  empId: '10001749',
};

export const approveDCRReasonsMock = [
  {
    id: 1,
    reason: 'Other',
    isFineApplicable: false,
    createdOn: '2021-11-25T00:00:00',
    createdBy: '15268',
  },
  {
    id: 2,
    reason: 'Due to technical issue (incomplete report)',
    isFineApplicable: false,
    createdOn: '2021-11-25T00:00:00',
    createdBy: '15268',
  },
  {
    id: 3,
    reason:
      'On Leave - due to family problem / function/marriage/accident/sickness)',
    isFineApplicable: false,
    createdOn: '2021-11-25T00:00:00',
    createdBy: '15268',
  },
  {
    id: 8,
    reason: 'Senior manager',
    isFineApplicable: true,
    createdOn: '2021-11-25T00:00:00',
    createdBy: '15268',
  },
];

export const DCRDataMock = {
  totalRecords: 3,
  gridData: [
    {
      requestId: 27,
      staffPositionId: 53743,
      employeeId: '10026587',
      employeeName: 'SANDEEP RAMAKANT MISHRA',
      unlockDate: '2021-12-01T00:00:00',
      raisedOn: '2021-12-23T00:00:00',
      approvedDate: null,
      unlockType: 'Unfilled DCR',
      unlockReason: 'Due to network issue / server problem',
      statusId: 4,
      status: null,
    },
    {
      requestId: 23,
      staffPositionId: 52043,
      employeeId: '10026230',
      employeeName: 'SIKANDAR GAUTAM',
      unlockDate: '2021-12-01T00:00:00',
      raisedOn: '2021-12-23T00:00:00',
      approvedDate: null,
      unlockType: 'Unfilled DCR',
      unlockReason: 'lock',
      statusId: 4,
      status: null,
    },
    {
      requestId: 103,
      staffPositionId: 65918,
      employeeId: '10021774',
      employeeName: 'RAMESH GUPTA',
      unlockDate: '2022-06-03T00:00:00',
      raisedOn: '2022-06-17T00:00:00',
      approvedDate: null,
      unlockType: 'Unfilled DCR',
      unlockReason: 'Due to technical issue (incomplete report)',
      statusId: 4,
      status: null,
    },
  ],
  filters: {
    approvalStatus: {
      filterData: [
        {
          id: 4,
          value: 'Pending FLM Approval',
          count: 10,
          parentId: null,
        },
        {
          id: 5,
          value: 'Pending SLM Approval',
          count: 11,
          parentId: null,
        },
        {
          id: 6,
          value: 'Pending Admin Approval',
          count: 0,
          parentId: null,
        },
      ],
    },
    zones: {
      filterData: [
        {
          id: 1502,
          value: 'NORTH',
          count: 9,
          parentId: null,
          regions: [
            {
              id: 5513,
              value: 'DELHI',
              count: 9,
              parentId: 1502,
            },
          ],
        },
        {
          id: 1501,
          value: 'SOUTH',
          count: 0,
          parentId: null,
        },
      ],
    },
    regions: {
      filterData: [
        {
          id: 5513,
          value: 'DELHI',
          count: 9,
          parentId: 1502,
        },
        {
          id: 5519,
          value: 'WEST BENGAL',
          count: 0,
          parentId: 1502,
        },
        {
          id: 5517,
          value: 'TAMIL NADU',
          count: 0,
          parentId: 1501,
        },
      ],
    },
    headQuarters: null,
    divisions: {
      filterData: [
        {
          id: 18,
          value: '3D-MANKIND',
          count: 3,
          parentId: null,
        },
        {
          id: 47,
          value: 'Ocularis Mankind',
          count: 1,
          parentId: null,
        },
      ],
    },
  },
};

export const positionManagementMock = {
  positionManagementMaster: {
    partyDetails: {
      from: {
        searchText: '12345',
        details: {
          divisionId: 1,
          empName: 'ANIL KUMAR',
          empid: '12345',
          roleId: 4,
          staffPositionId: 12345,
        },
      },
      to: {
        searchText: '45678',
        details: {
          divisionId: 2,
          empName: 'SUNIL KUMAR',
          empid: '45678',
          roleId: 4,
          staffPositionId: 45678,
        },
      },
    },
    multipleFormDetails: [
      {
        divisionId: 1,
        empName: 'ANIL KUMAR',
        empid: '12345',
        roleId: 4,
        staffPositionId: 12345,
      },
      {
        divisionId: 2,
        empName: 'Anil Chand',
        empid: '45678',
        roleId: 4,
        staffPositionId: 45678,
      },
    ],
    showTransferPartiesModal: false,
    partiesList: [
      {
        areas: [{id: 225581, name: 'SAHARSA'}],
        category: 'A',
        id: 175930,
        isKyc: false,
        name: 'C.M. CHOUDHARY',
        partyTypes: {
          id: 5,
          name: 'Doctor',
          shortName: null,
          partyTypeGroup: {id: 1, name: 'Doctor', shortName: 'Doctor'},
        },
        specialities: [
          {id: 19, name: 'OPTHALMOLOGIST', shortName: null, divisions: []},
        ],
      },
      {
        areas: [{id: 225581, name: 'SAHARSA'}],
        category: 'B',
        id: 123456,
        isKyc: true,
        name: 'Anil Kapoor',
        partyTypes: {
          id: 5,
          name: 'Doctor',
          shortName: null,
          partyTypeGroup: {id: 1, name: 'Doctor', shortName: 'Doctor'},
        },
        specialities: [
          {id: 19, name: 'OPTHALMOLOGIST', shortName: null, divisions: []},
        ],
      },
      {
        areas: [{id: 225581, name: 'SAHARSA'}],
        category: 'C',
        id: 45678,
        isKyc: true,
        name: 'Anil Kapoor',
        partyTypes: {
          id: 5,
          name: 'Chemist',
          shortName: null,
          partyTypeGroup: {id: 2, name: 'Chemist', shortName: 'Chemist'},
        },
        specialities: [],
      },
    ],
    areasList: [
      {
        areaId: 1,
        StaffPositionId: 123,
        areaID: 28819,
        areaName: 'Delhi',
        stationName: 'Abc',
        stationId: 12,
        poolName: 'Pool 1',
        poolId: 1234,
        stateName: 'Test 1',
        stateId: 1233,
        partyCount: 1,
        isActive: true,
      },
      {
        areaId: 2,
        staffPositionId: 1234,
        areaID: 28819,
        areaName: 'UP',
        stationName: 'Xyz',
        stationId: 124,
        poolName: 'Pool 2',
        poolId: 12342,
        stateName: 'Test 2',
        stateId: 12332,
        partyCount: 12,
        isActive: false,
      },
    ],
    partiesSelectedForTransfer: [
      {
        areas: [{id: 225581, name: 'SAHARSA'}],
        category: 'A',
        id: 175930,
        isKyc: false,
        name: 'C.M. CHOUDHARY',
        partyTypes: {
          id: 5,
          name: 'Doctor',
          shortName: null,
          partyTypeGroup: {id: 1, name: 'Doctor', shortName: 'Doctor'},
        },
        specialities: [
          {id: 19, name: 'OPTHALMOLOGIST', shortName: null, divisions: []},
        ],
      },
    ],
    cloningTimePeriod: [
      {id: 1, name: '3 Months'},
      {id: 2, name: '6 Months'},
      {id: 3, name: '12 Months'},
      {id: 4, name: 'No copy of transactional data'},
    ],
    positionType: 'transfer',
  },
};

export const partyFilterOptionsMock = {
  specialities: [
    {
      endDate: null,
      geoLocationTypeId: 10,
      geoNodeId: 226127,
      id: 226127,
      isExStation: false,
      name: 'DHAMANGAONRLY + MA,NGRULDUSTGIR',
      parentGeolocationId: 94860,
      poolId: 1072,
      poolName: 'AMRAVATI',
      shortName: null,
      startDate: '2022-06-24T00:00:00',
      stationId: 94860,
      stationName: 'DHAMANGAON RLY + MANGRUL DUSTGIR',
    },
  ],
  pools: [
    {
      endDate: null,
      geoLocationTypeId: 10,
      geoNodeId: 226127,
      id: 226127,
      isExStation: false,
      name: 'DHAMANGAONRLY + MA,NGRULDUSTGIR',
      parentGeolocationId: 94860,
      poolId: 1072,
      poolName: 'AMRAVATI',
      shortName: null,
      startDate: '2022-06-24T00:00:00',
      stationId: 94860,
      stationName: 'DHAMANGAON RLY + MANGRUL DUSTGIR',
    },
  ],
  stations: [
    {
      endDate: null,
      geoLocationTypeId: 10,
      geoNodeId: 226127,
      id: 226127,
      isExStation: false,
      name: 'DHAMANGAONRLY + MA,NGRULDUSTGIR',
      parentGeolocationId: 94860,
      poolId: 1072,
      poolName: 'AMRAVATI',
      shortName: null,
      startDate: '2022-06-24T00:00:00',
      stationId: 94860,
      stationName: 'DHAMANGAON RLY + MANGRUL DUSTGIR',
    },
  ],
  territories: [
    {
      endDate: null,
      geoLocationTypeId: 10,
      geoNodeId: 226127,
      id: 226127,
      isExStation: false,
      name: 'DHAMANGAONRLY + MA,NGRULDUSTGIR',
      parentGeolocationId: 94860,
      poolId: 1072,
      poolName: 'AMRAVATI',
      shortName: null,
      startDate: '2022-06-24T00:00:00',
      stationId: 94860,
      stationName: 'DHAMANGAON RLY + MANGRUL DUSTGIR',
    },
  ],
};

export const positionCloneListDataMock = [
  {
    id: 1,
    fromStaffPositionId: 1,
    toStaffPositionId: 2,
    statusId: 1,
    createdOn: '2022-08-10T04:46:06',
    reason: null,
  },
  {
    id: 2,
    fromStaffPositionId: 2,
    toStaffPositionId: 3,
    statusId: 3,
    createdOn: '2022-08-12T04:46:06',
    reason: 'Something went wrong',
  },
];

export const validateTransferResponseMock = {
  validParties: [],
  nonValidParties: [
    {partyId: 501231, areaId: 224396, areaName: 'JANAK PURI 2 (SPL)'},
    {partyId: 1560373, areaId: 224396, areaName: 'JANAK PURI 2 (SPL)'},
  ],
};

export const approvalWorkflowMock = [
  {
    isActive: true,
    maxLevel: 2,
    division: {
      id: 6,
      name: 'MANKIND',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    requestorConfigurations: [
      {
        id: 6,
        roleId: 2,
      },
      {
        id: 1029,
        roleId: 3,
      },
      {
        id: 2052,
        roleId: 4,
      },
    ],
    feature: {
      id: 1,
      name: 'DCR Uunlock',
      isActive: true,
    },
    id: 16,
    approvalLevelConfigurations: [
      {
        id: 0,
        level: 1,
        slaInHrs: 48,
      },
      {
        id: 0,
        level: 2,
        slaInHrs: 48,
      },
    ],
  },
  {
    id: 2,
    isActive: true,
    maxLevel: 2,
    division: {
      id: 1,
      name: '3D-MANKIND',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    requestorConfigurations: [
      {
        id: 18,
        roleId: 2,
      },
      {
        id: 1041,
        roleId: 3,
      },
      {
        id: 2064,
        roleId: 4,
      },
    ],
    feature: {
      id: 1,
      name: 'DCR Uunlock',
      isActive: true,
    },
    approvalLevelConfigurations: [],
  },
  {
    id: 3,
    isActive: true,
    maxLevel: 2,
    division: {
      id: 2,
      name: 'MANKIND',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    requestorConfigurations: [
      {
        id: 112,
        roleId: 2,
      },
      {
        id: 1135,
        roleId: 3,
      },
      {
        id: 2158,
        roleId: 4,
      },
    ],
    feature: {
      id: 2,
      name: 'DCR Modification',
      isActive: true,
    },
    approvalLevelConfigurations: [
      {
        id: 0,
        level: 1,
        slaInHrs: 48,
      },
      {
        id: 0,
        level: 2,
        slaInHrs: 48,
      },
    ],
  },
  {
    id: 4,
    isActive: true,
    maxLevel: 2,
    division: {
      id: 3,
      name: '3D-MANKIND',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    requestorConfigurations: [
      {
        id: 124,
        roleId: 2,
      },
      {
        id: 1147,
        roleId: 3,
      },
      {
        id: 2170,
        roleId: 4,
      },
    ],
    feature: {
      id: 2,
      name: 'DCR Modification',
      isActive: true,
    },
    approvalLevelConfigurations: [
      {
        id: 0,
        level: 1,
        slaInHrs: 48,
      },
      {
        id: 0,
        level: 2,
        slaInHrs: 48,
      },
    ],
  },
];

export const createWorkflowMock = {
  features: [
    {
      id: 1,
      name: 'DCR Unlock',
      isActive: true,
    },
    {
      id: 2,
      name: 'DCR Modification',
      isActive: false,
    },
  ],
  divisions: [
    {
      id: 1,
      name: 'Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 2,
      name: '3D-Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 3,
      name: 'Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 4,
      name: '3D-Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 5,
      name: 'Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 6,
      name: '3D-Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 7,
      name: 'Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 8,
      name: '3D-Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 9,
      name: 'Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 10,
      name: '3D-Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 11,
      name: 'Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 12,
      name: '3D-Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 13,
      name: 'Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 14,
      name: '3D-Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 15,
      name: 'Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 16,
      name: '3D-Mankind',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
  ],
  roles: [
    {
      id: 1,
      name: 'MR',
      staffPositionId: 0,
      rank: 2,
    },
    {
      id: 2,
      name: 'FLM',
      staffPositionId: 0,
      rank: 1,
    },
    {
      id: 3,
      name: 'SLM',
      staffPositionId: 0,
      rank: 1,
    },
    {
      id: 4,
      name: 'TLM',
      staffPositionId: 0,
      rank: 1,
    },
  ],
  minApprovers: [
    {
      id: 1,
      divisionId: 1,
      roleId: 3,
    },
    {
      id: 2,
      divisionId: 2,
      roleId: 2,
    },
  ],
};

export const bulkUpdateSummaryListMock = [
  {
    isDCRLocked: false,
    isSTPEditable: false,
    isMTPEditable: true,
    isDCREditable: true,
    isGspYearlyLocked: false,
    gspUnlockStatus: 1,
    errorDescription: 'GSP for SLM cannot be unlocked',
    staffPositionId: 65843,
    empId: '10014953',
    empName: 'A B VISHVAJITH',
    roleId: 3,
    regionName: 'TAMIL NADU',
    primaryHQName: '(p)NAGERCOIL',
    divisionName: 'MANKIND',
    managerName: 'M. MUGESH',
    stpStatusId: 0,
    isSTPLocked: false,
    stpUnlockEndDate: null,
    mtpStatusId: 0,
    hasMTPRecommendation: false,
    isMTPLocked: false,
    mtpUnlockEndDate: null,
    dcrStatus: false,
    mtpSortOrder: 2,
  },
  {
    isDCRLocked: false,
    isSTPEditable: false,
    isMTPEditable: true,
    isDCREditable: true,
    isGspYearlyLocked: false,
    gspUnlockStatus: 1,
    errorDescription: 'GSP for SLM cannot be unlocked',
    staffPositionId: 65844,
    empId: '10014953',
    empName: 'ARUN SINGH',
    roleId: 3,
    regionName: 'TAMIL NADU',
    primaryHQName: '(p) DELHI',
    divisionName: 'MANKIND',
    managerName: 'B. Kumar',
    stpStatusId: 0,
    isSTPLocked: false,
    stpUnlockEndDate: null,
    mtpStatusId: 0,
    hasMTPRecommendation: false,
    isMTPLocked: false,
    mtpUnlockEndDate: null,
    dcrStatus: false,
    mtpSortOrder: 2,
  },
];

export const bulkUpdateSummaryListWebAccessMock = {
  '13232': true,
  '12234': true,
};

export const masterConfigDataMock = {
  features: [
    {id: 1, name: 'DCR Uunlock', isActive: true},
    {id: 2, name: 'DCR Modification', isActive: true},
    {id: 3, name: 'MTP Approval for MR (Compliance > 70%)', isActive: true},
    {id: 4, name: 'MTP Approval for MR (Compliance <= 70%)', isActive: true},
    {id: 5, name: 'MTP Approval for Managers ', isActive: true},
  ],
  divisions: [
    {
      id: 1,
      name: 'LIFESTAR',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 2,
      name: 'LIFESTAR-2',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 5,
      name: 'KAM MANKIND',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 3,
      name: 'INSTITUTIONAL -LSK',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 4,
      name: 'LIFESTAR-3 D',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
    {
      id: 15,
      name: 'DISCOVERY',
      shortName: null,
      maxPatchCount: null,
      kycPartyLimit: null,
      divisionGroupId: 0,
    },
  ],
  roles: [
    {
      id: 2,
      name: 'Second Line Manager',
      staffPositionId: 0,
      rank: 3,
      shortName: 'SLM',
    },
    {
      id: 3,
      name: 'First Line Manager',
      staffPositionId: 0,
      rank: 2,
      shortName: 'FLM',
    },
    {
      id: 4,
      name: 'Medical Representative',
      staffPositionId: 0,
      rank: 1,
      shortName: 'MR',
    },
    {
      id: 5,
      name: 'Third Line Manager',
      staffPositionId: 0,
      rank: 4,
      shortName: '3LM',
    },
    {
      id: 6,
      name: 'Fourth Line Manager',
      staffPositionId: 0,
      rank: 5,
      shortName: '4LM',
    },
    {
      id: 7,
      name: 'Fifth Line Manager',
      staffPositionId: 0,
      rank: 6,
      shortName: '5LM',
    },
    {
      id: 8,
      name: 'State Head',
      staffPositionId: 0,
      rank: 7,
      shortName: 'State Head',
    },
    {
      id: 9,
      name: 'Top Line Manager',
      staffPositionId: 0,
      rank: 8,
      shortName: 'TLM',
    },
  ],
  minApprovers: [
    {id: 1, divisionId: 1, roleId: 2, minimumApproverId: 5},
    {id: 2, divisionId: 1, roleId: 3, minimumApproverId: 7},
    {id: 3, divisionId: 1, roleId: 4, minimumApproverId: 2},
    {id: 4, divisionId: 1, roleId: 5, minimumApproverId: 7},
    {id: 5, divisionId: 1, roleId: 6, minimumApproverId: 8},
    {id: 6, divisionId: 1, roleId: 7, minimumApproverId: 9},
    {id: 7, divisionId: 2, roleId: 2, minimumApproverId: 6},
    {id: 8, divisionId: 2, roleId: 3, minimumApproverId: 5},
    {id: 9, divisionId: 2, roleId: 4, minimumApproverId: 2},
    {id: 10, divisionId: 2, roleId: 5, minimumApproverId: 7},
    {id: 11, divisionId: 2, roleId: 6, minimumApproverId: 8},
    {id: 12, divisionId: 2, roleId: 7, minimumApproverId: 9},
    {id: 13, divisionId: 3, roleId: 2, minimumApproverId: 6},
    {id: 14, divisionId: 3, roleId: 3, minimumApproverId: 5},
    {id: 15, divisionId: 3, roleId: 4, minimumApproverId: 2},
    {id: 16, divisionId: 3, roleId: 5, minimumApproverId: 7},
    {id: 17, divisionId: 3, roleId: 6, minimumApproverId: 8},
    {id: 18, divisionId: 3, roleId: 7, minimumApproverId: 9},
    {id: 19, divisionId: 4, roleId: 2, minimumApproverId: 6},
    {id: 20, divisionId: 4, roleId: 3, minimumApproverId: 5},
    {id: 21, divisionId: 4, roleId: 4, minimumApproverId: 2},
    {id: 22, divisionId: 4, roleId: 5, minimumApproverId: 7},
    {id: 23, divisionId: 4, roleId: 6, minimumApproverId: 8},
    {id: 24, divisionId: 4, roleId: 7, minimumApproverId: 9},
    {id: 25, divisionId: 5, roleId: 2, minimumApproverId: 6},
    {id: 26, divisionId: 5, roleId: 3, minimumApproverId: 5},
    {id: 27, divisionId: 5, roleId: 4, minimumApproverId: 2},
    {id: 28, divisionId: 5, roleId: 5, minimumApproverId: 7},
    {id: 29, divisionId: 5, roleId: 6, minimumApproverId: 8},
    {id: 30, divisionId: 5, roleId: 7, minimumApproverId: 9},
    {id: 85, divisionId: 15, roleId: 2, minimumApproverId: 6},
    {id: 86, divisionId: 15, roleId: 3, minimumApproverId: 5},
    {id: 87, divisionId: 15, roleId: 4, minimumApproverId: 2},
    {id: 88, divisionId: 15, roleId: 5, minimumApproverId: 7},
    {id: 89, divisionId: 15, roleId: 6, minimumApproverId: 8},
    {id: 90, divisionId: 15, roleId: 7, minimumApproverId: 9},
    {id: 1024, divisionId: 1, roleId: 8, minimumApproverId: 9},
    {id: 1025, divisionId: 2, roleId: 8, minimumApproverId: 9},
    {id: 1026, divisionId: 3, roleId: 8, minimumApproverId: 9},
    {id: 1027, divisionId: 4, roleId: 8, minimumApproverId: 9},
    {id: 1028, divisionId: 5, roleId: 8, minimumApproverId: 9},
    {id: 1038, divisionId: 15, roleId: 8, minimumApproverId: 9},
    {id: 1151, divisionId: 1, roleId: 9, minimumApproverId: 9},
    {id: 1152, divisionId: 2, roleId: 9, minimumApproverId: 9},
    {id: 1153, divisionId: 3, roleId: 9, minimumApproverId: 9},
    {id: 1154, divisionId: 4, roleId: 9, minimumApproverId: 9},
    {id: 1155, divisionId: 5, roleId: 9, minimumApproverId: 9},
    {id: 1165, divisionId: 15, roleId: 9, minimumApproverId: 9},
  ],
};

export const ActivityListData = {
  activityTypeList: [
    {
      id: 31,
      name: 'PriyaMeeting',
      shortName: null,
      isActive: true,
      isFieldActivity: false,
      isDisplay: true,
      isActiveForMRs: null,
      isActiveForManagers: null,
    },
    {
      id: 32,
      name: 'HelloActivity',
      shortName: null,
      isActive: true,
      isFieldActivity: false,
      isDisplay: true,
      isActiveForMRs: null,
      isActiveForManagers: null,
    },
  ],
  activityList: [
    {
      id: 136,
      name: 'priyaactivity',
      shortName: null,
      activityTypeId: 31,
      isDeleted: false,
      isActive: true,
      isActiveForMRs: true,
      isActiveForManagers: true,
      wsfaCode: null,
      fromDate: null,
      toDate: null,
      partyId: [],
      activityType: null,
    },
  ],
  adminAccessDivisions: [
    {
      id: 14,
      name: 'LIFESTAR',
      parent: null,
    },
    {
      id: 35,
      name: 'MAGNET',
      parent: null,
    },
    {
      id: 37,
      name: 'MANKIND',
      parent: null,
    },
    {
      id: 39,
      name: 'DISCOVERY',
      parent: null,
    },
    {
      id: 40,
      name: 'FUTURE MANKIND',
      parent: null,
    },
    {
      id: 59,
      name: '3D-MANKIND',
      parent: null,
    },
    {
      id: 65,
      name: 'NOBELIS',
      parent: null,
    },
    {
      id: 68,
      name: 'CURIS',
      parent: null,
    },
    {
      id: 80,
      name: 'STAR MANKIND',
      parent: null,
    },
    {
      id: 88,
      name: 'Gravitas Mankind',
      parent: null,
    },
    {
      id: 91,
      name: 'Onco Trust',
      parent: null,
    },
    {
      id: 93,
      name: 'Diacar Alpha',
      parent: null,
    },
    {
      id: 96,
      name: 'Procare',
      parent: null,
    },
    {
      id: 119,
      name: 'Cerebris',
      parent: null,
    },
    {
      id: 124,
      name: 'Life Mankind',
      parent: null,
    },
    {
      id: 126,
      name: 'ZESTEVA',
      parent: null,
    },
    {
      id: 129,
      name: 'Aspiris Mankind',
      parent: null,
    },
    {
      id: 131,
      name: 'Ocularis Mankind',
      parent: null,
    },
  ],
};

export const ActivityMappingMockData = {
  unmappedActivitiesList: {
    unMappedActivities: [
      {
        id: 111100,
        name: 'SALES REVIEW MEETING-Test1',
      },
      {
        id: 111101,
        name: 'SALES REVIEW MEETING-Test2',
      },
    ],
    transferFailedActivities: [
      {
        combinationId: 100,
        supermanActivities: [
          {
            id: 99,
            name: 'DOCTOR VISIT',
          },
        ],
      },
      {
        combinationId: 101,
        supermanActivities: [
          {
            id: 27,
            name: 'Camp',
          },
          {
            id: 99,
            name: 'DOCTOR VISIT',
          },
        ],
      },
      {
        combinationId: 102,
        supermanActivities: [
          {
            id: 29,
            name: 'Transit',
          },
        ],
      },
      {
        combinationId: 103,
        supermanActivities: [
          {
            id: 99,
            name: 'DOCTOR VISIT',
          },
          {
            id: 29,
            name: 'Transit',
          },
        ],
      },
      {
        combinationId: 104,
        supermanActivities: [
          {
            id: 27,
            name: 'Camp',
          },
          {
            id: 17,
            name: 'CLASS ROOM TRAINING',
          },
          {
            id: 99,
            name: 'DOCTOR VISIT',
          },
          {
            id: 16,
            name: 'FIELD TRAINING',
          },
          {
            id: 15,
            name: 'REFRESHER COURSE',
          },
          {
            id: 18,
            name: 'WSFA TRAINING',
          },
        ],
      },
    ],
  },
  mappedActivitiesList: [
    {
      wsfaActivity: {
        id: 59,
        name: 'ADMIN WORK',
      },
      mappedSmActivities: [
        {
          combinationId: 44,
          hasError: false,
          supermanActivities: [
            {
              id: 30,
              name: 'Admin Day',
            },
          ],
        },
      ],
    },
  ],
  newAddedActivity: {
    wsfaActivity: {
      id: 21,
      name: 'ANY OTHER REASON',
    },
    mappedSmActivities: [
      {
        combinationId: 'new_21_0',
        supermanActivities: [
          {
            id: 21,
            name: 'ANY OTHER REASON',
            copied: true,
          },
          {
            id: 30,
            name: 'Admin Day1',
            moved: true,
          },
        ],
      },
    ],
  },
  removedActivity: {
    deleted: true,
    wsfaActivityId: 59,
    combinationId: 44,
    hasError: false,
    supermanActivities: [
      {
        id: 30,
        name: 'Admin Day',
      },
    ],
  },
  activityList: ActivityListData,
  activitiesListGridOptions: {pageSize: 10, pageNo: 1},
};

export const bulkOptionsData = {
  webAccess: [
    {label: '1 Month', value: 1},
    {label: '3 Months', value: 3},
    {label: '6 Months', value: 6},
    {label: 'Always', value: 120},
  ],
  stp: [
    {label: 'No Record', value: 0},
    {label: 'Pending', value: 1},
    {label: 'Both', value: 2},
  ],
  mtp: [
    {label: 'No Record', value: 0},
    {label: 'Work In Progress', value: 1},
    {label: 'Pending FLM Approval', value: 5},
    {label: 'Pending SLM Approval', value: 6},
    {label: 'Pending Admin', value: 7},
  ],
  dcr: [{label: 'Disabled', value: 1}],
};

export const gspFinancialYearMock = [2021, 2022, 2023, 2024];
export const gspScheduleListMock = [
  {
    divisionId: 129,
    divisionName: 'Aspiris Mankind',
    unlockedStartDate: null,
    unlockedEndDate: null,
    isActive: true,
  },
  {
    divisionId: 119,
    divisionName: 'Cerebris',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-27T11:59:59',
    isActive: true,
  },
  {
    divisionId: 88,
    divisionName: 'Gravitas Mankind',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-30T23:59:59',
    isActive: true,
  },
  {
    divisionId: 65,
    divisionName: 'NOBELIS',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-30T23:59:59',
    isActive: true,
  },
  {
    divisionId: 131,
    divisionName: 'Ocularis Mankind',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-30T23:59:59',
    isActive: true,
  },
  {
    divisionId: 1,
    divisionName: 'Aspiris Mankind',
    unlockedStartDate: null,
    unlockedEndDate: null,
    isActive: true,
  },
  {
    divisionId: 2,
    divisionName: 'Cerebris',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-27T11:59:59',
    isActive: true,
  },
  {
    divisionId: 3,
    divisionName: 'Gravitas Mankind',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-30T23:59:59',
    isActive: true,
  },
  {
    divisionId: 4,
    divisionName: 'NOBELIS',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-30T23:59:59',
    isActive: true,
  },
  {
    divisionId: 5,
    divisionName: 'Ocularis Mankind',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-30T23:59:59',
    isActive: true,
  },
  {
    divisionId: 6,
    divisionName: 'Aspiris Mankind',
    unlockedStartDate: null,
    unlockedEndDate: null,
    isActive: true,
  },
  {
    divisionId: 7,
    divisionName: 'Cerebris',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-27T11:59:59',
    isActive: true,
  },
  {
    divisionId: 8,
    divisionName: 'Gravitas Mankind',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-30T23:59:59',
    isActive: true,
  },
  {
    divisionId: 9,
    divisionName: 'NOBELIS',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-30T23:59:59',
    isActive: true,
  },
  {
    divisionId: 10,
    divisionName: 'Ocularis Mankind',
    unlockedStartDate: '2022-12-01T00:00:00',
    unlockedEndDate: '2023-06-30T23:59:59',
    isActive: true,
  },
];

export const dataCorrectionMock = {
  partiesList: [
    {
      id: 434343,
      staffPositionId: 123,
      userId: 123,
      partyId: 12322,
      empCode: 343232,
      wsfaCode: 43535353,
      name: 'A B Vishvajith',
      startDate: '2023-11-22T04:43:46.705Z',
      endDate: '2023-11-26T04:43:46.705Z',
      partyType: 'Doctor',
      area: 'Gurugram',
      speciality: 'GP',
    },
    {
      id: 23232,
      staffPositionId: 12323,
      userId: 12323,
      partyId: 1223322,
      empCode: 34233232,
      wsfaCode: 4332535353,
      name: 'Vijay',
      startDate: '2023-11-22T04:43:46.705Z',
      endDate: '2023-12-15T04:43:46.705Z',
      partyType: 'Doctor',
      area: 'Delhi',
      speciality: 'Ortho',
    },
    {
      id: 2233232,
      staffPositionId: 142323,
      userId: 123235,
      partyId: 12233422,
      empCode: 3423132,
      wsfaCode: 4535353,
      name: 'Sumit',
      startDate: '2023-11-11T04:43:46.705Z',
      endDate: '2023-12-15T04:43:46.705Z',
      partyType: 'Doctor',
      area: 'Delhi',
      speciality: 'Ortho',
    },
  ],
};
export const pendencyMock = {
  adminPendencyReportDtos: [
    {
      userId: 67398,
      userName: 'MRUTHYUNJAY CHINNAPPANAVAR',
      sapCode: '10021450',
      reportingTo: 'RAVISHANKAR SHETTY',
      id: 0,
      itemId: 76463,
      itemCode: '114273',
      itemName: 'LIPIKIND DMF BADGES-MKD',
      pack: '',
      quantity: 0,
      pendingSince30Days: 0,
      pendingSince45Days: 0,
      searchDataItemSample: '114273|LIPIKIND DMF BADGES-MKD',
      isSample: false,
    },
  ],

  totalRecords: '86277',
};
export const pendencyExcelData = {
  adminPendencyReportDtos: [
    {
      userId: 67398,
      userName: 'MRUTHYUNJAY CHINNAPPANAVAR',
      sapCode: '10021450',
      reportingTo: 'RAVISHANKAR SHETTY',
      id: 0,
      itemId: 76463,
      itemCode: '114273',
      itemName: 'LIPIKIND DMF BADGES-MKD',
      pack: '',
      quantity: 0,
      pendingSince30Days: 0,
      pendingSince45Days: 0,
      searchDataItemSample: '114273|LIPIKIND DMF BADGES-MKD',
      isSample: false,
    },
    {
      userId: 66825,
      userName: 'JAYDIP PAUL',
      sapCode: '10001887',
      reportingTo: 'MANI BHUSHAN KUMAR',
      id: 0,
      itemId: 132847,
      itemCode: '117152',
      itemName: 'DMF T-SHIRTS -MKD',
      pack: '',
      quantity: 1,
      pendingSince30Days: 0,
      pendingSince45Days: 0,
      searchDataItemSample: '117152|DMF T-SHIRTS -MKD',
      isSample: false,
    },
    {
      userId: 44152,
      userName: 'RAMNESH RAMAN JHA',
      sapCode: '10001533',
      reportingTo: 'DHANANJAY SHARMA',
      id: 0,
      itemId: 134157,
      itemCode: '118193',
      itemName: 'BUDAWAYS PEN -STARMKD',
      pack: '',
      quantity: 1,
      pendingSince30Days: 0,
      pendingSince45Days: 0,
      searchDataItemSample: '118193|BUDAWAYS PEN -STARMKD',
      isSample: false,
    },
    {
      userId: 44152,
      userName: 'RAMNESH RAMAN JHA',
      sapCode: '10001533',
      reportingTo: 'DHANANJAY SHARMA',
      id: 0,
      itemId: 132847,
      itemCode: '117152',
      itemName: 'DMF T-SHIRTS -MKD',
      pack: '',
      quantity: 5,
      pendingSince30Days: 0,
      pendingSince45Days: 0,
      searchDataItemSample: '117152|DMF T-SHIRTS -MKD',
      isSample: false,
    },
    {
      userId: 44152,
      userName: 'RAMNESH RAMAN JHA',
      sapCode: '10001533',
      reportingTo: 'DHANANJAY SHARMA',
      id: 0,
      itemId: 135729,
      itemCode: '119681',
      itemName: 'MONTE CARLO SWEATSHIRT-MKD',
      pack: '',
      quantity: 1,
      pendingSince30Days: 0,
      pendingSince45Days: 0,
      searchDataItemSample: '119681|MONTE CARLO SWEATSHIRT-MKD',
      isSample: false,
    },
  ],
  totalRecords: '86277',
};
export const ledgerItem = [
  {
    label: 'PATIENT EDUCATION LEAFLET STAND -3DMKD',
    value: 76622,
  },
  {
    label: 'LULIBRUT UMBRELLA -3DMKD',
    value: 76673,
  },
  {
    label: 'ALLEN SOLLY SHIRT -3DMKD',
    value: 77755,
  },
  {
    label: 'THINKER UNFOLDING THE UNLIMITED ENG-MKD',
    value: 76692,
  },
  {
    label: 'DERMA ROLLER 1.5MM',
    value: 77885,
  },
  {
    label: 'OPALWARE DINNER SET 15PCS(BOROSIL)-3DMKD',
    value: 131340,
  },
  {
    label: 'PEN WITH CAPSULE SHAPE CARD -3DMKD',
    value: 131370,
  },
  {
    label: 'STUDDS HELMET (CHROME) - MKD',
    value: 76005,
  },
  {
    label: 'XAMPU KZ ACRYLIC PAPER WEIGHT -3DMKD',
    value: 132670,
  },
  {
    label: 'HAND GLOVES (50 PACK) - 3DMKD',
    value: 77522,
  },
  {
    label: 'ELECTRIC GLASS KETTLE 1.8LTR -3DMKD',
    value: 132763,
  },
  {
    label: 'PITHU BAG-MKD',
    value: 75272,
  },
];
