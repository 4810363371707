import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {makeStyles} from '@mui/styles';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {FormGroup} from '@mui/material';
import {Stack} from '@mui/system';
import {statusManagerStateSelector} from '@app/screens/module-specific-utilities/pages/status-manager/redux/selectors';
import {statusManagerStateActions} from '@app/screens/module-specific-utilities/pages/status-manager/redux';
import theme from '@app/themes';
import {strings} from '@app/common/strings';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

export const StatusManagerNavBar = () => {
  const dispatch = useDispatch();
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const status = useSelector(statusManagerStateSelector.getActiveStatus());
  const classes = useStyles();

  const activeInactive = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(statusManagerStateActions.setActiveStatus(event?.target?.checked));
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={theme.colors.LIGHT_NAVY_BLUE}
          fontWeight={theme.sizing.weight800}>
          {navbarComponentHeading}
        </Typography>
      </div>
      <FormGroup>
        <Stack
          direction="row"
          style={{justifyContent: 'flex-end'}}
          spacing={1}
          alignItems="center">
          <Typography
            color={theme.colors.LIGHT_NAVY_BLUE}
            fontWeight={theme.sizing.weight800}
            fontFamily={theme.fonts.poppins}
            fontSize={theme.sizing.fontSize}>
            {strings.inActive}
          </Typography>
          <AntSwitch
            onChange={activeInactive}
            checked={status}
            inputProps={{'aria-label': 'ant design'}}
          />

          <Typography
            color={theme.colors.LIGHT_NAVY_BLUE}
            fontWeight={theme.sizing.weight800}
            fontFamily={theme.fonts.poppins}
            fontSize={theme.sizing.fontSize}>
            {strings.active}
          </Typography>
        </Stack>
      </FormGroup>
    </div>
  );
};
