
export const styles = {
  buttonStyle: {
    width: 133.3,
    height: 42.7,
    borderRadius: 2,
  },
};
export const inTransitChallanTableStyle = {
  container: {
    fontFamily: 'Poppins',
    fontSize: 9.3,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.19,
    textAlign: 'left',
    color: '#494761',
    wordWrap: 'break-word',
  },
  containerVoucher: {
    maxWidth: 90,
    fontFamily: 'Poppins',
    fontSize: 9.3,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.19,
    textAlign: 'left',
    color: '#494761',
  },
  containerMargin: {
    maxWidth: 90,
    fontFamily: 'Poppins',
    fontSize: 9.3,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.19,
    textAlign: 'left',
    color: '#494761',
  },
  containerTransport: {
    fontFamily: 'Poppins',
    fontSize: 9.3,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.19,
    textAlign: 'left',
    color: '#494761',
    paddingLeft: 0,
  },
  checkboxContainer: {
    maxWidth: 90,
    backgroundColor: '#f4f4f4',
    borderBottomColor: '#f4f4f4',
    boxshadow: '0 0 5px 1px lightgrey',
    color: '#f4f4f4',
    width: 41,
  },
  voucherNumber: {
    maxWidth: 100,
    fontFamily: 'Poppins',
    fontSize: 9.5,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.19,
    textAlign: 'left',
    color: '#322b7c',
  },
  numberContainer: {
    maxWidth: 90,
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: -0.48,
    textAlign: 'left',
    color: '#322b7c',
  },
  codeContainer: {
    maxWidth: 90,
    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.38,
    textAlign: 'left',
    color: '#1c1939',
  },
  codeContainerName: {
    maxWidth: 100,
    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.38,
    textAlign: 'left',
    color: '#1c1939',
  },
  codeContainerTransport: {
    maxWidth: 100,
    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.38,
    textAlign: 'left',
    color: '#1c1939',
    paddingLeft: 0,
  },
  codeContainerWidth: {
    maxWidth: 128,
    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.38,
    textAlign: 'left',
    color: '#1c1939',
  },
  codeContainerVoucher: {
    maxWidth: 90,
    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.38,
    textAlign: 'left',
    color: '#1c1939',
  },
  codeContainerMargin: {
    maxWidth: 90,
    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.38,
    textAlign: 'left',
    color: '#1c1939',
  },
  icon24Checkbox: {
    width: 24,
    height: 24,
    marginLeft: -1,
  },
  DatGridStyle: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: -3.5,
  },
  gridPadding: {
    paddingTop: 4,
  },
  dropDownStyle: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'flex-end',
  },
  formControlStyle: {
    margin: '12px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
    marginLeft: 20,
  },
  cellStyle: {
    color: '#33C7FF',
  },
  container: {
    fontFamily: 'Poppins-Regular',
    fontSize: 12.7,
    fontWeight: 'bolder',
    letterSpacing: 0,
    textTransform: 'none',
    textAlign: 'left',
    color: '#494761',
  },
  ChallanTableCell: {
    color: '#494761',
    fontSize: '12.7px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins-Regular',
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  QtyformControl: {
    right: 381,
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
    width:'100%'
  },
  QtyformContainer: {
    display: 'flex',
    paddingTop: '15px',
  },
  serachFormControl: {
    borderRadius: '0px',
    padding: '0px',
    opacity: '0.6',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: '12px',
    display: 'block',
    right: 360,
    width:'100%'
  },
  searchbar: {
    borderRadius: '0px',
    width: '-webkit-fill-available',
    padding: '0px',
    opacity: '0.6',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: '12px',
    display: 'contents',
  },
  reports: {
    padding: '8px 22px',
    height: 51,
    width:'100%'
  },
};
