import {useEffect, useState} from 'react';
import {Box, Chip, TextField, FormHelperText} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {planAndMeetSelector} from '../../pages/plan-and-meet/redux';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {useStyles} from './styles';
import {
  STP_UNLOCK_DATE_ERROR,
  MTP_UNLOCK_DATE_ERROR,
  MONTH_NAME_FORMAT,
  CALENDAR_DATE_FORMAT,
} from '@app/common/constants';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import addDays from 'date-fns/addDays';
import dayjs from 'dayjs';
import {getDaysLeftInMonth, getFormatDate} from '@app/utils/dateTimeHelper';
import {getPlanAndMeetObject} from '@app/helper/plan-and-meet';
import {fetchMTPUnlockReasonsCreator} from '../../pages/plan-and-meet/redux/slice';

export const STPMTPUnlockAction = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const gracePeriodDate = addDays(new Date(), 2);
  const [unlockDate, setUnlockDate] = useState(gracePeriodDate);
  const [fine, setFine] = useState<string>('With Fine');
  const [dateError, setDateError] = useState<string | null>(null);
  const [fineReason, setFineReason] = useState('');
  const [openCalendar, setOpenCalendar] = useState(false);
  const [defaultGracePeriod, setDefaultGracePeriod] = useState<boolean>(true);
  const planAndMeetState = useSelector(
    planAndMeetSelector.getPlanAndMeetState(),
  );
  const isCurrentMonth = getPlanAndMeetObject(planAndMeetState)?.isCurrentMonth;
  const mtpUnlockReasons = useSelector(
    planAndMeetSelector.getMTPUnlockReasons(),
  );
  const maxDate = isCurrentMonth
    ? addDays(new Date(), 14)
    : lastDayOfMonth(new Date());
  const dateErrorMessage = isCurrentMonth
    ? STP_UNLOCK_DATE_ERROR
    : MTP_UNLOCK_DATE_ERROR;

  const minDate = dayjs().toDate();
  const disableMTPGracePeriod = !isCurrentMonth && getDaysLeftInMonth() < 3;

  const getSTPUnlockTillInfoMessage = () => {
    const stpUnlockFormattedDate = getFormatDate({
      date: defaultGracePeriod ? gracePeriodDate : unlockDate,
      format: MONTH_NAME_FORMAT,
    });
    return isCurrentMonth
      ? `STP for the user will be unlocked till ${stpUnlockFormattedDate}. Upcoming month MTP unlock not permitted.`
      : `MTP will be unlocked till ${stpUnlockFormattedDate}.`; // Please select a date between ${minFormattedDate} and ${maxFormattedDate} for MTP unlock.`;
  };

  useEffect(() => {
    if (!isCurrentMonth) {
      dispatch(fetchMTPUnlockReasonsCreator());
    }
  }, []);

  useEffect(() => {
    if (defaultGracePeriod) {
      setUnlockDate(gracePeriodDate);
      props?.handleEndDate(gracePeriodDate);
    }
  }, [defaultGracePeriod]);

  const handleChipClick = (isDefault: boolean) => {
    setDefaultGracePeriod(isDefault);
  };

  const handleFineRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFine((event.target as HTMLInputElement).value);
    setFineReason('');
    props?.handleMtpUnlockReason('');
  };

  const handleReasonChange = (event: SelectChangeEvent) => {
    setFineReason(event.target.value);
    props?.handleMtpUnlockReason(event.target.value);
  };

  const handleChange = (newValue: any) => {
    setUnlockDate(newValue);
    props?.handleEndDate(newValue);
    setOpenCalendar((calendarOpen: any) => !calendarOpen);
  };

  const handleOpenCalandar = () => {
    setOpenCalendar((calendarOpen: any) => !calendarOpen);
    setDefaultGracePeriod(false);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.chipContent}>
        <Chip
          label="Unlock for a grace period of 2 days"
          disabled={disableMTPGracePeriod}
          onClick={() => handleChipClick(true)}
          className={
            defaultGracePeriod ? classes.chipDefault : classes.chipSelectDate
          }
          data-testid="chip-2-days"
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            open={openCalendar}
            value={unlockDate}
            disablePast
            disabled={defaultGracePeriod}
            inputFormat="dd-MM-yyyy"
            onChange={newValue => {
              handleChange(newValue);
            }}
            onError={error => setDateError(error)}
            InputProps={{
              classes: {root: classes.root},
            }}
            PopperProps={{
              placement: 'auto',
            }}
            maxDate={maxDate}
            minDate={minDate}
            renderInput={params => (
              <div className={classes.chipContent}>
                <TextField
                  style={{opacity: 0, width: 0, height: 0}}
                  {...params}
                />
                <Chip
                  label={
                    defaultGracePeriod
                      ? 'Select end Date'
                      : getFormatDate({
                          date: unlockDate,
                          format: CALENDAR_DATE_FORMAT,
                        })
                  }
                  clickable
                  className={
                    !defaultGracePeriod
                      ? classes.chipDefault
                      : classes.chipSelectDate
                  }
                  variant="outlined"
                  onClick={() => handleOpenCalandar()}
                />
              </div>
            )}
          />
        </LocalizationProvider>
      </Box>

      {dateError && <FormHelperText error>{dateErrorMessage}</FormHelperText>}
      {minDate &&
        (dateError !== null ? (
          <FormHelperText error>{getSTPUnlockTillInfoMessage()}</FormHelperText>
        ) : (
          <FormHelperText>{getSTPUnlockTillInfoMessage()}</FormHelperText>
        ))}
      {!isCurrentMonth && (
        <Box display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} marginY={'0px'} paddingLeft={0.5}>
            <FormControl>
              <RadioGroup
                name="controlled-radio-buttons-group"
                row={false}
                value={fine}
                className={classes.radioGroup}
                onChange={handleFineRadioChange}>
                <FormControlLabel
                  value="With Fine"
                  control={<Radio size="small" />}
                  label="With Fine"
                  className={classes.radioLabel}
                />
                <FormControlLabel
                  value="Without Fine"
                  control={<Radio size="small" />}
                  label="Without Fine"
                  className={classes.radioLabel}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <FormControl size="small" sx={{height: 43}}>
            <InputLabel id="demo-select-small">Select Reason</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={fineReason}
              label="Select Reason"
              onChange={handleReasonChange}>
              {mtpUnlockReasons.length > 0 &&
                mtpUnlockReasons.map((item: any) => {
                  if (
                    (fine === 'With Fine' && item.isFineApplicable) ||
                    (fine === 'Without Fine' && !item.isFineApplicable)
                  ) {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.reason}
                      </MenuItem>
                    );
                  }
                })}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};
