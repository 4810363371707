import {STRINGS} from '@app/common/constants';
import {strings} from '@app/common/strings';
import {monthMock} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {popUpStyle} from '@app/components/widgets/popupCss/popUpStyles';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {fetchExpenseRejectStatusCreator} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import theme from '@app/themes';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const styles = {
  approveDialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  approveDialogContent: {
    display: 'flex',
    width: '100%',
  },
  dialogPaperBox: {
    borderRadius: 30,
    boxShadow: '20px',
    width: '40%',
    padding: 20,
  },
  reasonTextField: {
    width: '100%',
    backgroundColor: theme.colors.grey[2500],
    borderRadius: 2,
    '& .MuiFormLabel-asterisk': {
      color: theme.colors.redish,
    },
  },
  minMax: {color: theme.colors.redish, fontSize: '10px'},
  checkboxContainer: {display: 'flex', paddingTop: 20, marginLeft: -15},
  font14: {fontSize: 14},
  font16: {fontSize: 16},
  font20: {fontSize: 20},
  textFieldInputProps: {maxLength: 50, minLength: 5},
};
export const util: any = {
  handleRejectClose: null,
  handleCheckBoxChange: null,
  handleReasonChange: null,
  rejectExpense: null,
};
export const RejectExpenseModal = () => {
  const dispatch = useDispatch();

  // selected row from staff dropdown
  const adminExpenseSelectedRow = useSelector(
    approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
  );

  //  open modal
  const isModalOpen = useSelector(
    approveExpenseStateSelector.getIsRejectExpenseModalOpen(),
  );

  //   checkbox
  const isCheckBoxEnabled = useSelector(
    approveExpenseStateSelector.getRejectExpenseModalCheckbox(),
  );

  //   close modal and edeselect checkbox
  const handleRejectClose = () => {
    dispatch(approveExpenseStateActions.setOpenRejectExpenseModal(false));
    dispatch(approveExpenseStateActions.setRejectExpenseModalCheckbox(false));
    setAdminComments('');
  };

  // select/de-select checkbox
  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(
      approveExpenseStateActions.setRejectExpenseModalCheckbox(
        event?.target?.checked,
      ),
    );

  // admin comments field
  const [adminComments, setAdminComments] = React.useState<string>('');
  // select/de-select checkbox
  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminComments(
      event?.target?.value?.replace(/[^a-zA-Z0-9 ]/, '')?.trimStart(),
    );
    if (event?.target?.value?.length < 5) {
      dispatch(approveExpenseStateActions.setRejectExpenseModalCheckbox(false));
    }
  };

  const rejectExpense = () => {
    let requestBody = {
      expenseHistoryId: adminExpenseSelectedRow?.expenseHistoryId,
      adminComments: adminComments,
    };
    dispatch(fetchExpenseRejectStatusCreator(requestBody));
    setAdminComments('');
  };
  util.handleRejectClose = handleRejectClose;
  util.handleCheckBoxChange = handleCheckBoxChange;
  util.handleReasonChange = handleReasonChange;
  util.rejectExpense = rejectExpense;

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleRejectClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      PaperProps={{
        style: styles.dialogPaperBox,
      }}>
      <DialogTitle id="alert-dialog-title">
        {`${STRINGS.moduleSpecificUtilities.approveExpense.screen2.approveModal.rejectExpensesQuestion}`}
      </DialogTitle>
      <DialogContent>
        <Box sx={{...styles.approveDialogContent, mt: 1, mb: 3}}>
          <div>
            <Typography sx={styles.font20} color={`${theme.colors.redish}`}>
              ₹ {adminExpenseSelectedRow?.totalClaimedAmount?.toFixed(0)}
            </Typography>
            <Typography sx={styles.font14} color="text.secondary">
              {strings.moduleSpecificUtilities.claimedAmount}
            </Typography>
          </div>
          <Divider orientation="vertical" flexItem sx={{marginLeft: 2}} />
          <div style={{marginLeft: 20}}>
            <Typography sx={styles.font20} color="primary">
              ₹ {adminExpenseSelectedRow?.totalSystemAmount?.toFixed(0)}
            </Typography>
            <Typography sx={styles.font14} color="text.secondary">
              {strings.moduleSpecificUtilities.systemGeneratedAmount}
            </Typography>
          </div>
        </Box>
        <Box>
          <TextField
            id="filled-textarea"
            required
            label="Reason"
            placeholder="Reason"
            multiline
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={styles.textFieldInputProps}
            rows={4}
            sx={styles.reasonTextField}
            value={adminComments}
            onChange={handleReasonChange}
          />
        </Box>
        <div>
          <Typography px-4 sx={styles.minMax}>
            {strings.moduleSpecificUtilities.minMaxChar}
          </Typography>
        </div>
        <div style={styles.checkboxContainer}>
          <Checkbox
            checked={isCheckBoxEnabled}
            onChange={handleCheckBoxChange}
            inputProps={{'aria-label': 'controlled'}}
            disabled={adminComments?.length < 5}
          />{' '}
          <Typography
            sx={styles.font14}
            display="inline"
            color="text.secondary">
            {strings.moduleSpecificUtilities.aboutToReject}
            <Typography
              sx={styles.font16}
              display="inline"
              color={`${theme.colors.blue[100]}`}>
              {adminExpenseSelectedRow?.staffName}{' '}
            </Typography>
            {strings.moduleSpecificUtilities.expenseOfMonth}
            <Typography sx={styles.font16} display="inline" color="primary">
              {
                monthMock()?.find(
                  month => month?.key === adminExpenseSelectedRow?.expenseMonth,
                )?.value
              }
              {' , '}
              {adminExpenseSelectedRow?.expenseYear}
            </Typography>
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Box sx={styles.approveDialogActions}>
          <Button
            variant="contained"
            sx={popUpStyle.saveButton}
            disabled={!isCheckBoxEnabled}
            onClick={rejectExpense}>
            {strings.yes}
          </Button>

          <Button
            variant="contained"
            sx={popUpStyle.resetButton}
            onClick={handleRejectClose}>
            {strings.no}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
