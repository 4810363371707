import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';
export const useStyles = makeStyles({
  card:{
    display:'flex',    
  },
  container: {
    flexDirection: 'column',
    background: Colors.background,
    borderRadius: 10,
    padding: 25,
    display: 'flex',
  },
  dialog: {
    display: 'flex',
    width: '100%',
  },
  root: {
    flexDirection: 'row-reverse',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },
  headerTitle: {
    padding: 0,
    marginLeft: 15,
  },
  contentContainer: {
    background: Colors.background,
    borderRadius: 15,
    padding: '15px 25px',
  },
  label: {
    borderBottom: '1px solid black',
    width: 'fit-content',
    marginTop: 10,
    marginBottom: 20,
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    padding: 10,
  },
  paperButton: {
    border: `2px solid ${Colors.primary}`,
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.primary,
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
  applyButton: {
    border: `2px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
    '&:disabled': {
      backgroundColor: Colors.grey[100],
      border: `3px solid ${Colors.grey[100]}`,
    },
  },
  actions:{
    flexDirection:'row',
    display:'flex',
    overflow:'hidden',
    justifyContent: 'center'
  },
  arrowIcon: {
    position:'absolute',
    top:'50%',
    display:'flex',
    alignItems:'center',
    zIndex:1,
    padding:0,
  }
});
