import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';
export const useStyles = makeStyles({
  container: {
    flexDirection: 'column',
    // background: Colors.background,
    borderRadius: 10,
    // padding: 25,
    display: 'flex',
    overflow:'auto',
  },
  dialog: {
    display: 'flex',
    width: '100%',
  },
  chipContent:{
    display:'flex',
    flexDirection:'column',
  },
  root: {
    flexDirection: 'row-reverse',
    '& input':{
        padding:7
    }
  },
  boxtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    padding: 10,
    borderTop: '1px solid #322b7c',
  },
  paperButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.primary,
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
  applyButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
    '&:disabled': {
      backgroundColor: Colors.grey[100],
      border: `3px solid ${Colors.grey[100]}`,
    },
  },
  title: {
    padding: 0,
    marginLeft: 15,
    marginTop: 10,
    marginBottom: 5,
  },
  chipDefault: {
    background: Colors.blue[400],
    color: Colors.primary,
    fontWeight: 'bold',
    fontSize:10,
    marginBottom:5
  },
  chipSelectDate: {
    background: Colors.background,
    color: Colors.primary,
    fontSize:10,
    marginBottom:5
  },
  radioGroup:{
    flexDirection:'row'
  },
  radioLabel: {
    marginRight:15,
    '& .MuiFormControlLabel-label':{
      fontSize:10,      
    }
  }
});
