import {
  DataGrid,
  DataGridProps,
  GridCellParams,
  GridColDef,
  GridPreProcessEditCellProps,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import {useEffect, useState} from 'react';
import {Box, Button} from '@mui/material';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {useDispatch, useSelector} from 'react-redux';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import clsx from 'clsx';
import {dailyDataGrid} from '@app/screens/Styles/style';
import {fetchBilDetailsCreator} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {strings} from '@app/common/strings';

export const util: any = {
  processRowUpdate: null,
  billPath: null,
  adminApprovedAmountCalculations: null,
};

export const getadminApprovedAmount = (
  isDisable: any,
  adminApprovedAmount_id: any,
  adminApprovedAmount_Value: any,
  taRate: any,
  setSaveValidationTa: any,
) => {
  return {
    field: strings.moduleSpecificUtilities.adminPassedAmount,
    headerName: strings.moduleSpecificUtilities.ADMINPASSEDTA,
    width: 120,
    editable: isDisable,
    cellClassName: (params: GridCellParams<number>) =>
      clsx('super-app', {
        disable: params?.row?.isRuleEngineCalculated,
      }),
    valueGetter: (params: GridValueGetterParams) => {
      return adminApprovedAmount_id === params?.row?.id
        ? adminApprovedAmount_Value * taRate
        : params?.row?.adminApprovedAmount;
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      setSaveValidationTa(true);
      return {...params?.props};
    },
    valueParser: (value: any) => {
      return value
        ?.replace(/\D/g, '')
        .substring(0, value?.length - (value?.length - 6));
    },
  };
};
export const getadminRemarks = (
  isDisable: any,
  setAdminReason: any,
  adminReason: any,
  setSaveValidationTa: any,
) => {
  return {
    field: strings.moduleSpecificUtilities.adminRemarks,
    headerName:
      strings.ModuleSpecificUtilitie.approveExpense.ADMINCHANGEREASONTA,
    width: 200,
    editable: isDisable,
    valueParser: (value: any) => {
      let res = value?.replace(/[^a-zA-Z0-9 ]/g, '')?.trimStart();
      if (res?.length <= 50) {
        setAdminReason(res);
        return res;
      } else {
        return adminReason;
      }
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const reason = params?.props?.value;
      setSaveValidationTa(true);
      const totalDistanceModified =
        params?.otherFieldsProps !== undefined
          ? params?.otherFieldsProps?.totaldistance.value !==
              params?.row?.totaldistance ||
            params?.otherFieldsProps?.adminApprovedAmount?.value !==
              params?.row?.adminApprovedAmount
          : false;
      const validateReason =
        reason !== null && reason !== undefined ? reason?.length < 5 : true;
      const hasError2 = totalDistanceModified
        ? totalDistanceModified && validateReason
        : validateReason;
      return {...params?.props, error: hasError2};
    },
  };
};
export const gettotaldistance = (
  isDisable: any,
  setSaveValidationTa: any,
  setAdminApprovedAmount_id: any,
  setAdminApprovedAmount_Value: any,
) => {
  return {
    field: strings.moduleSpecificUtilities.totaldistance,
    headerName: strings.moduleSpecificUtilities.ADMINPASSEDDISTANCE,
    width: 120,
    editable: isDisable,
    cellClassName: (params: GridCellParams<number>) =>
      clsx('super-app', {
        enable: params?.row?.isRuleEngineCalculated,
        disable: !params?.row?.isRuleEngineCalculated,
      }),
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const amount = params?.props?.value;
      const hasError = amount < 0;
      setSaveValidationTa(true);
      setAdminApprovedAmount_id(params?.row?.id);
      setAdminApprovedAmount_Value(hasError ? 0 : amount);

      return {...params?.props, error: hasError};
    },
    valueParser: (value: any) => {
      return value?.replace(/\D/g, '');
    },
  };
};
interface Props {
  gridData: any;
  isDisable: boolean;
}

export default function TaDataGrid(props: Readonly<Props>) {
  const dispatch = useDispatch();
  const [adminApprovedAmount_Value, setAdminApprovedAmount_Value] = useState(0);
  const [saveValidationTa, setSaveValidationTa] = useState(false);
  const [adminApprovedAmount_id, setAdminApprovedAmount_id] =
    useState<number>(0);
  const [adminReason, setAdminReason] = useState('');
  const taRate = useSelector(approveExpenseStateSelector.getTaRate());
  const taGrid = useSelector(approveExpenseStateSelector.getTaGrid());
  const adminApprovedAmountCalculations = (newRow: any, index: any) => {
    return Number(newRow?.totaldistance) * Number(taRate);
  };
  useEffect(() => {
    dispatch(approveExpenseStateActions.setDisableSave(saveValidationTa));
  }, [saveValidationTa]);
  const processRowUpdate: DataGridProps['processRowUpdate'] = (newRow: any) => {
    const tempArrTa = (taGrid && [...taGrid]) ?? [];
    const index = tempArrTa?.findIndex(
      (element: {id: number}) => element?.id === newRow?.id,
    );
    setAdminApprovedAmount_Value(0);
    setAdminApprovedAmount_id(0);
    setSaveValidationTa(false);
    tempArrTa[index] = {
      ...tempArrTa?.[index],
      totaldistance: newRow?.totaldistance,
      adminApprovedAmount:
        taGrid?.[index]?.totaldistance != newRow?.totaldistance
          ? adminApprovedAmountCalculations(newRow, index)
          : newRow?.adminApprovedAmount,
      adminRemarks: newRow?.adminRemarks,
    };

    dispatch(approveExpenseStateActions.setTaGrid(tempArrTa));

    return newRow;
  };
  const billPath = (params: any) => {
    let request = {
      id: params?.row?.id,
      page: strings.moduleSpecificUtilities.taDatPopup,
    };
    dispatch(fetchBilDetailsCreator(request));
    dispatch(approveExpenseStateActions.setBillPathArr([]));
    dispatch(
      approveExpenseStateActions.setBillPathArr(
        params?.row?.billpath?.split(','),
      ),
    );
    dispatch(approveExpenseStateActions.setViewBillPopupVisible(true));
  };
  const columns: GridColDef[] = [
    {
      field: strings.moduleSpecificUtilities.systemAmount,
      headerName: strings.ta,
      width: 70,
      editable: false,
    },
    {
      field: strings.moduleSpecificUtilities.customAmount,
      headerName: strings.moduleSpecificUtilities.TACLAIMED,
      width: 120,
      type: 'number',
      editable: false,
    },
    {
      field: strings.moduleSpecificUtilities.REMARKS,
      headerName: strings.moduleSpecificUtilities.TACHANGEREASON,
      type: 'date',
      width: 160,
      editable: false,
    },
    {
      field: strings.moduleSpecificUtilities.managerApprovedAmount,
      headerName: strings.moduleSpecificUtilities.MANAGERPASSEDTA,
      width: 120,
      editable: false,
    },
    {
      field: strings.moduleSpecificUtilities.managerRemarks,
      headerName: strings.moduleSpecificUtilities.MANAGERREMARKSTA,
      width: 120,
      editable: false,
    },
    gettotaldistance(
      props?.isDisable,
      setSaveValidationTa,
      setAdminApprovedAmount_id,
      setAdminApprovedAmount_Value,
    ),
    getadminRemarks(
      props?.isDisable,
      setAdminReason,
      adminReason,
      setSaveValidationTa,
    ),
    getadminApprovedAmount(
      props?.isDisable,
      adminApprovedAmount_id,
      adminApprovedAmount_Value,
      taRate,
      setSaveValidationTa,
    ),

    {
      field: strings.moduleSpecificUtilities.billpath,
      headerName: strings.moduleSpecificUtilities.bills,
      width: 100,
      editable: false,
      renderCell: (params: any) => {
        if (params?.row?.isBillUploaded) {
          return (
            <Button
              sx={{fontSize: 11, lineHeight: 1.45}}
              size="small"
              data-testid={`billPath`}
              onClick={() => billPath(params)}
              variant="contained">
              {strings.view}
            </Button>
          );
        }
      },
    },
  ];

  util.processRowUpdate = processRowUpdate;
  util.billPath = billPath;
  util.adminApprovedAmountCalculations = adminApprovedAmountCalculations;

  const boxHeight =
    props?.gridData?.length < 5 ? props?.gridData?.length * 30 + 70 : 190;
  return (
    <Box sx={dailyDataGrid(boxHeight, 50)}>
      <DataGrid
        columnBuffer={9}
        rows={props.gridData}
        columns={columns}
        getRowId={row => row?.id}
        editMode="row"
        experimentalFeatures={{newEditingApi: true}}
        processRowUpdate={processRowUpdate}
        rowHeight={30}
        headerHeight={40}
        hideFooter={true}
      />
    </Box>
  );
}
