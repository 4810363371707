import {useState, useRef, useEffect} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import {useStyles} from './styles';
import {Close, KeyboardArrowLeft , KeyboardArrowRight } from '@mui/icons-material';
import {Label} from '@app/components/elements/Label';
import {useDispatch, useSelector} from 'react-redux';
import {
  bulkGSPUpdateCreator,
  planAndMeetSelector,
  webAccessUnlockCreator,
  stpBulkUpdateCreator,
  planAndMeetStatusStateActions,
  mtpBulkUpdateCreator,
  dcrBulkUpdateCreator,
  enableUnfilledDCRCreator,
  stpBulkUnlockCreator,
  mtpBulkUnlockCreator,
} from '../../pages/plan-and-meet/redux';
import Colors from '@app/themes/colors';
import {getPlanAndMeetObject} from '@app/helper/plan-and-meet';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ROLES,BULK_UPDATE_ACTIONS, DCR_UNLOCK_STATUS_VALUE, DCR_APPROVED, BULK_UPDATE_ACTIONS_TITLE, BULK_ACTIONS_NEXT_MONTH } from '@app/common/constants';
import GenericAction from '../generic-action'
import {bulkOptionsData} from '@app/mocks'
import {dcrUnlockRequestSelector} from '../../pages/dcr-unlock-request/redux';
import {appSelector} from '@app/store/selectors';
import {ConfirmationDialog as Confirmation} from '@app/components/widgets/ConfirmationDialog';

export interface IProps {
  open: boolean;
  onClose: (status: boolean) => void;
}
interface ActionPerformed {
  gsp?:any;
  web?:any;
  mtp?:any;
  stp?:any;
  reviewDcr?:any;
  enableDcr?:any;
}

const scrollingWidth = 250

const BulkUpdateDialog = (props: IProps) => {
  const cardsRef = useRef<any>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const planAndMeetState = useSelector(
    planAndMeetSelector.getPlanAndMeetState(),
  );
  const isCurrentMonth = getPlanAndMeetObject(planAndMeetState)?.isCurrentMonth;
  const [actionPerformed,setActionPerformed] = useState<ActionPerformed>({})
  const [dropdownValueSelected,setDropdownValueSelected] = useState<any>({})
  const [selectedCard, setSelectedCard] = useState(isCurrentMonth ? BULK_UPDATE_ACTIONS.GSP : BULK_UPDATE_ACTIONS.MTP);
  const totalRows = useSelector(planAndMeetSelector.getPlanAndMeetTotalRow());
  const [openConfirmation, setOpenConfirmation] = useState<any>(false);
  const [openApplyConfirmation, setOpenApplyConfirmation] = useState<any>(false);
  const [valueSelected,setValueSelected] = useState(false)
  const [scrollOffset,setScrollOffset] = useState(0)
  const [hideLeftArrow,setHideLeftArrow] = useState(true)
  const [hideRightArrow,setHideRightArrow] = useState(false)
  const [showError,setShowError] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')
  const [cardIndex,setCardIndex] = useState(0);
  const totalGSPUnlockEligibleCount = useSelector(
    planAndMeetSelector.getTotalGSPUnlockEligibleCount(),
  );
  const totalWebUnlockEligibleCount = useSelector(
    planAndMeetSelector.getTotalWebUnlockEligibleCount(),
  );
  const allStaffPositionIds = useSelector(planAndMeetSelector.getAllStaffPositionIds());
  const allSelected = useSelector(planAndMeetSelector.getIsAllSelected());
  const selectedRows = useSelector(planAndMeetSelector.getSelectedRows());
  const deselectedRows = useSelector(
    planAndMeetSelector.getAllDeSelectedRows(),
  );
  const atHierarchyLevel = useSelector(
    planAndMeetSelector.getIsAtHierarchyLevel(),
  );
  const dcrUnlockReasons = useSelector(
    dcrUnlockRequestSelector.getDCRUnlockReasons(),
  );
  const [dcrReasons, setDcrReasons] = useState([]);
  const [enableDCRRemarks, setEnableDCRRemarks] = useState('');
  const [stpMtpUnlockEndDate, setStpMtpUnlockEndDate] = useState<Date | null>();
  const [mtpUnlockReason, setMtpUnlockReason] = useState<string>();
  const userDetails = useSelector(appSelector.getUserInfo());  

  useEffect(() => {
    const reasons = dcrUnlockReasons?.map((reason:any) => ({label: reason?.reason, value: reason?.id}));
    setDcrReasons(reasons);
  }, [dcrUnlockReasons])
  

  const onApplyClick = () => {
    if((actionPerformed?.web || selectedCard === BULK_UPDATE_ACTIONS.WEB) && !actionPerformed?.gsp){
      bulkUpdate(BULK_UPDATE_ACTIONS.WEB)
    }else if(actionPerformed?.gsp){
      bulkUpdate(BULK_UPDATE_ACTIONS.GSP)
    }else {    
      bulkUpdate(selectedCard)
    } 
  };

  const getStaffPositionArray = () => {
    if(allSelected) {
      const deselectedIds = deselectedRows?.map((id: any) => id.staffPositionId);
      return allStaffPositionIds.filter((item: number) => !deselectedIds.includes(item));
    } else {
      return selectedRows?.map((id: any) => id.staffPositionId);
    }
  }

  const bulkUpdate = (type:any) => {
    const apiData = {
      staffPositionIds: getStaffPositionArray(),
      atHierarchyLevel: atHierarchyLevel,
      isCurrentMonth,
    };

    if(BULK_UPDATE_ACTIONS.GSP === type){
      dispatch(bulkGSPUpdateCreator(apiData));
    }else if(BULK_UPDATE_ACTIONS.WEB === type) {
      const obj = {...apiData,isLockedRequest:actionPerformed?.web, duration: dropdownValueSelected[BULK_UPDATE_ACTIONS.WEB]}
      dispatch(webAccessUnlockCreator({body:obj}));
    }else if(BULK_UPDATE_ACTIONS.STP === type){
      const statusID = dropdownValueSelected?.stp + 1;     
      dispatch(stpBulkUpdateCreator({...apiData,statusId:statusID || 2}))
    }else if(BULK_UPDATE_ACTIONS.MTP === type){
      dispatch(mtpBulkUpdateCreator({...apiData,fromStatus:dropdownValueSelected.mtp !== 'undefined' ? dropdownValueSelected?.mtp : 1,toStatus:3,isDCREnable: false,isCurrentMonth}))
    }else if(BULK_UPDATE_ACTIONS.REVIEW_DCR === type){
      dispatch(dcrBulkUpdateCreator({...apiData}))
    }else if(BULK_UPDATE_ACTIONS.ENABLE_DCR === type){
      const body = {
        ...apiData,
        dcrUnlockReasonId: dropdownValueSelected?.enableDcr,
        managerStaffPositionId: userDetails?.staffPositionId,
        remarks: enableDCRRemarks,
        status: Number(
          Object.keys(DCR_UNLOCK_STATUS_VALUE).find(
            key => DCR_UNLOCK_STATUS_VALUE[key as keyof object] === DCR_APPROVED,
          ),
        ),
      }
      dispatch(enableUnfilledDCRCreator({body, isBulkUpdate: true}))
    }else if(BULK_UPDATE_ACTIONS.UNLOCK_STP === type){
      dispatch(stpBulkUnlockCreator({...apiData, endDate: stpMtpUnlockEndDate}));
    }else if(BULK_UPDATE_ACTIONS.UNLOCK_MTP === type){
      dispatch(mtpBulkUnlockCreator({...apiData, endDate: stpMtpUnlockEndDate, unlockReasonId: mtpUnlockReason}));
    }

    dispatch(planAndMeetStatusStateActions.setBulkActionSelected(type))
    props.onClose(true);
  
  };

  const getSelectedCount = () => {
    if (allSelected && !deselectedRows?.length) {
      return totalRows;
    } else if (!allSelected && selectedRows?.length) {
      return selectedRows?.length;
    } else if (allSelected && deselectedRows?.length > 0) {
      return totalRows - deselectedRows?.length;
    }
  };

  const handleClose = (event: Event, reason: string) => {
    if (reason && reason == 'backdropClick') return;
    props.onClose(false);
  };

  const closeDialog = () => {
    props.onClose(false);
  };


  const getGSPUnlockCount = () => {       
    if(allSelected && !deselectedRows?.length){
      return totalGSPUnlockEligibleCount
    }else if(allSelected && deselectedRows?.length){
      const count = deselectedRows?.filter((obj:any) => obj?.roleId === ROLES.MR && obj?.isGspYearlyLocked)
      return totalGSPUnlockEligibleCount - count?.length
    }else if(!allSelected && selectedRows?.length){
      const count = selectedRows?.filter((obj:any) => obj?.roleId === ROLES.MR && obj?.isGspYearlyLocked)?.length
      return count;
    }
  }

  const getWebUnlockCount = () => {       
    if(allSelected && !deselectedRows?.length){
      return totalWebUnlockEligibleCount
    }else if(allSelected && deselectedRows?.length){
      const count = deselectedRows?.filter((obj:any) => obj?.roleId === ROLES.MR && !obj?.isWebAccessLocked)
      return totalWebUnlockEligibleCount - count?.length
    }else if(!allSelected && selectedRows?.length){
      const count = selectedRows?.filter((obj:any) => obj?.roleId === ROLES.MR && !obj?.isWebAccessLocked)?.length
      return count;
    }
  }

  const pluralize = (count: number, word: string) => {
    return `${count} ${count > 1 ? word+'s': word}`;
  }

  const resetCardsValue = () => {
    setDropdownValueSelected({});
    setActionPerformed({});
    setValueSelected(false)
    setErrorMessage('')
    setShowError(false)
  }

  const handleSwitchChange = (val:any,type:any) => {
    const value = val ? val : !val
    if(valueSelected && (Object.keys(dropdownValueSelected)?.[0] !== selectedCard || Object.keys(actionPerformed)?.[0] !== selectedCard)) {
      resetCardsValue()
    }
    if(type !== BULK_UPDATE_ACTIONS.GSP && type !== BULK_UPDATE_ACTIONS.REVIEW_DCR){
      setActionPerformed({[type]:!val,})
      type !== BULK_UPDATE_ACTIONS.WEB && setDropdownValueSelected({[type]:1})
      setValueSelected(value);
      if(getSelectedCount() !== getWebUnlockCount() && type === BULK_UPDATE_ACTIONS.WEB){
        setShowError(true)
        setErrorMessage(`${getWebUnlockCount() > 0 ? 'Only ' : ''} ${pluralize(getWebUnlockCount(),'record')} eligible for Web Access Lock.\n Do you want to proceed?`)
      }
      if(val && type === BULK_UPDATE_ACTIONS.WEB) {
        setShowError(false)
        setErrorMessage('');
      }
    }else{
      setValueSelected(value);
      setActionPerformed({[type]:val,})
      setDropdownValueSelected({})
      if(getSelectedCount() !== getGSPUnlockCount() && type === BULK_UPDATE_ACTIONS.GSP ){
        setShowError(true)
        setErrorMessage(`${getGSPUnlockCount() > 0 ? 'Only ' : ''} ${pluralize(getGSPUnlockCount(),'record')} eligible for GSP Unlock.\n Do you want to proceed?`)
      }
    }
  }

  const handleAccessDurationSelected = (val:any,type:any) => {
    const obj = {[type]:val}
    if(type !== BULK_UPDATE_ACTIONS.GSP && type !== BULK_UPDATE_ACTIONS.WEB){
      setActionPerformed({[type]:val})
    }
    setDropdownValueSelected(obj)
    setValueSelected(true)      
  }

  const selectCard = (type: string) => {
    const switchVal = selectedCard === BULK_UPDATE_ACTIONS.WEB ? !valueSelected : valueSelected;
    if(switchVal && (Object.keys(dropdownValueSelected)?.length || Object.keys(actionPerformed)?.length)) {
      if(type !== selectedCard) {
        setOpenConfirmation(type);        
      } 
    } else {
      setSelectedCard(type);
    }
  }

  const closeConfirmation = (response: boolean) => {
    if(response) {
      setDropdownValueSelected({});
      setActionPerformed({});
      setSelectedCard(openConfirmation);
      resetCardsValue();
    }
    setOpenConfirmation(false);
  }

  const openApplyConfirmationModal = () => {
    setOpenApplyConfirmation(true);
  }

  const closeApplyConfirmation = (response: boolean) => {
    response && onApplyClick();
    setOpenApplyConfirmation(false);
  }

  const changeCardIndex = (index:any) => {    
    const actionList = isCurrentMonth ? BULK_UPDATE_ACTIONS : BULK_ACTIONS_NEXT_MONTH;
    Object.values(actionList)?.forEach((val:any,i:any) => {
      if(i === index){
       selectCard(val)
      }
    })
  }

  const handleScroll = (dir:string) => {
    const maxScrollCount = isCurrentMonth ? 6 : 1;
    const element = cardsRef?.current;
    const totalWidth = element?.scrollWidth - element?.clientWidth;
    let scrollWidth = scrollOffset;
    let index = cardIndex;
    if(dir === 'right'){
      scrollWidth =  scrollOffset + scrollingWidth;
      index = index + 1     
    } else{
      scrollWidth =  scrollOffset - scrollingWidth;
      index = index - 1      
    }        
    changeCardIndex(index)
    if (index === 0) {
      setHideLeftArrow(true)
      setHideRightArrow(false)
    }else if(index > 0 && index < maxScrollCount){
      setHideLeftArrow(false)
      setHideRightArrow(false)
    } else if(index === maxScrollCount){
      setHideRightArrow(true)
      setHideLeftArrow(false)
    }
    setCardIndex(index)
    element?.scrollTo({left:scrollWidth,behavior: 'smooth' })
    setScrollOffset(scrollWidth)
  }

  const handleRemarks = (val: string) => {
    setEnableDCRRemarks(val);
  }

  const handleEndDate = (val: Date) => {
    setStpMtpUnlockEndDate(val);
  }

  const handleMtpUnlockReason = (val: string) => {
    setMtpUnlockReason(val);
  }

  const isDropdownValueSelected = () => {
    if (((!actionPerformed.web && selectedCard === BULK_UPDATE_ACTIONS.WEB) ||
        selectedCard === BULK_UPDATE_ACTIONS.STP ||
        selectedCard === BULK_UPDATE_ACTIONS.MTP ||
        selectedCard === BULK_UPDATE_ACTIONS.ENABLE_DCR)) {
        if(dropdownValueSelected[selectedCard] || dropdownValueSelected[selectedCard] === 0) {
          return true;
        } else {
          return false;
        }
    }
    return true;
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      classes={{paper: classes.dialog}}
      disableEscapeKeyDown
      scroll="paper">
      <Box className={classes.headerContainer}>
        <DialogTitle className={classes.headerTitle}>Bulk Change</DialogTitle>
        <IconButton
          color="primary"
          aria-label="dialog-close"
          component="span"
          id="dialog-close"
          onClick={closeDialog}
          data-testid={'dialog-close'}>
          <Close />
        </IconButton>
      </Box>
     
      <Box className={classes.contentContainer}>
        <Label
          title={`${pluralize(getSelectedCount(),'record')} selected`}
          textColor={Colors.primary}
          size={12}
          hideToolTip
        />
 
        <Label
          title={`Selected action -> ${BULK_UPDATE_ACTIONS_TITLE[selectedCard as keyof object]}`}
          size={16}
          fontWeight={'700'}
          classes={classes.label}
          hideToolTip

        />          
        <Box className={`${classes.actions}  hide-scrollbar`} ref={cardsRef}>  
          {!hideLeftArrow && 
            <IconButton
              color="primary"
              aria-label="dialog-arrow-left"
              component="span"
              id="dialog-arrow-left"
              onClick={() => handleScroll('left')}
              data-testid={'dialog-arrow-left'}
              className={classes.arrowIcon}
              sx={{left:10}}>
              <KeyboardArrowLeft color='primary' sx={{ fontSize: 40 }}/>
            </IconButton>  
          }
          <div className={classes.card}>
              {isCurrentMonth &&
              <>
              <GenericAction actionType={BULK_UPDATE_ACTIONS.GSP} title={"GSP"} handleChange={handleSwitchChange} disable={selectedCard !== BULK_UPDATE_ACTIONS.GSP} handleCardSelected={selectCard} selectedCard={selectedCard} switchVal={valueSelected}/>
              <GenericAction actionType={BULK_UPDATE_ACTIONS.WEB} title={"Web Access"} handleChange={handleSwitchChange} handleDropdownSelected={handleAccessDurationSelected} disable={selectedCard !== BULK_UPDATE_ACTIONS.WEB} handleCardSelected={selectCard} selectedCard={selectedCard} dropdownData={bulkOptionsData.webAccess} switchVal={!valueSelected} dropdownValue={dropdownValueSelected}/>
              <GenericAction actionType={BULK_UPDATE_ACTIONS.STP} title={"STP Status"} handleChange={handleSwitchChange} handleDropdownSelected={handleAccessDurationSelected} disable={selectedCard !== BULK_UPDATE_ACTIONS.STP} handleCardSelected={selectCard} selectedCard={selectedCard} dropdownData={bulkOptionsData.stp} dropdownValue={dropdownValueSelected}/>
              </>
              }
              <GenericAction actionType={BULK_UPDATE_ACTIONS.MTP} title={"MTP Status"} handleChange={handleSwitchChange} handleDropdownSelected={handleAccessDurationSelected} disable={selectedCard !== BULK_UPDATE_ACTIONS.MTP} handleCardSelected={selectCard} selectedCard={selectedCard} dropdownData={bulkOptionsData.mtp} dropdownValue={dropdownValueSelected}/>
              {isCurrentMonth &&
              <>
              <GenericAction actionType={BULK_UPDATE_ACTIONS.REVIEW_DCR} title={"Review DCR"} handleChange={handleSwitchChange} handleDropdownSelected={handleAccessDurationSelected} disable={selectedCard !== BULK_UPDATE_ACTIONS.REVIEW_DCR} handleCardSelected={selectCard} selectedCard={selectedCard} dropdownData={bulkOptionsData.dcr} dropdownValue={dropdownValueSelected} switchVal={valueSelected}/>
              <GenericAction actionType={BULK_UPDATE_ACTIONS.ENABLE_DCR} title={"Enable DCR"} handleChange={handleSwitchChange} handleDropdownSelected={handleAccessDurationSelected} disable={selectedCard !== BULK_UPDATE_ACTIONS.ENABLE_DCR} handleCardSelected={selectCard} selectedCard={selectedCard} dropdownData={dcrReasons} dropdownValue={dropdownValueSelected} handleRemarks={handleRemarks}/>
              <GenericAction actionType={BULK_UPDATE_ACTIONS.UNLOCK_STP} title={"STP Unlock"} handleChange={handleSwitchChange} handleDropdownSelected={handleAccessDurationSelected} disable={selectedCard !== BULK_UPDATE_ACTIONS.UNLOCK_STP} handleCardSelected={selectCard} selectedCard={selectedCard} isSTPMTPCard={true} isCurrentMonth={isCurrentMonth} handleEndDate={handleEndDate}/>
              </>
              }
              {!isCurrentMonth && <GenericAction actionType={BULK_UPDATE_ACTIONS.UNLOCK_MTP} title={"MTP Unlock"} handleChange={handleSwitchChange} handleDropdownSelected={handleAccessDurationSelected} disable={selectedCard !== BULK_UPDATE_ACTIONS.UNLOCK_MTP} handleCardSelected={selectCard} selectedCard={selectedCard} isSTPMTPCard={true} isCurrentMonth={isCurrentMonth} handleEndDate={handleEndDate} handleMtpUnlockReason={handleMtpUnlockReason}/>}
          </div>  
          {!hideRightArrow &&
          <IconButton
            color="primary"
            aria-label="dialog-arrow-right"
            component="span"
            id="dialog-arrow-right"
            onClick={() => handleScroll('right')}
            data-testid={'dialog-arrow-right'}
            className={classes.arrowIcon}
            sx={{right:10}}>
            <KeyboardArrowRight  sx={{ fontSize: 40 }}/>
          </IconButton>
        }
        </Box>
      </Box>
      {showError &&
        <Alert severity="error" >
          <AlertTitle>Warning</AlertTitle>
          {errorMessage}
        </Alert>
      }
      <DialogActions>
        <Box className={classes.dialogActions}>
          <Button
            data-testid="button-clear"
            variant="contained"
            className={classes.paperButton}
            onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            data-testid="button-apply"
            variant="contained"
            className={classes.applyButton}
            onClick={openApplyConfirmationModal}
            disabled={(!actionPerformed?.gsp && selectedCard === BULK_UPDATE_ACTIONS.GSP) || 
              (!actionPerformed?.reviewDcr && selectedCard === BULK_UPDATE_ACTIONS.REVIEW_DCR) ||
              (selectedCard === BULK_UPDATE_ACTIONS.ENABLE_DCR && !(enableDCRRemarks?.length && actionPerformed?.enableDcr)) ||
              (selectedCard === BULK_UPDATE_ACTIONS.UNLOCK_MTP && !mtpUnlockReason) ||
              !isDropdownValueSelected()
            }            
            >
            Proceed
          </Button>
        </Box>
      </DialogActions>

      <Confirmation
        open={openApplyConfirmation}
        message={`${BULK_UPDATE_ACTIONS_TITLE[selectedCard as keyof object]} for ${pluralize(getSelectedCount(),'record')} will be updated. Do you want to proceed?`}
        onClose={closeApplyConfirmation}
      />
      <ConfirmationDialog open={openConfirmation ? true : false} onClose={closeConfirmation} />
    </Dialog>
  );
};
export default BulkUpdateDialog;

export const ConfirmationDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: any;
}) => {

  const handleClose = (event: any, reason: string) => {
    if (reason && reason == "backdropClick") 
        return;
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Changes made earlier will be discarded. Do you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="button-cancel" onClick={() => onClose(false)}>
          No
        </Button>
        <Button data-testid="button-ok" onClick={() => onClose(true)} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
)}
