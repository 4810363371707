import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {Button, Stack} from '@mui/material';
import {useCallback, useEffect} from 'react';
import {configurationManagementStateActions} from '@app/screens/configuration-management/components/redux';
import {trackEvent} from '@app/utils/analytics';
import {
  EVENT_NAME_ACTIVE_BUTTON,
  EVENT_NAME_ADD_NEW_EXPENSE_DA_MASTER,
} from '@app/utils/analytics/constants';
import {ConfigurationManagementSelector} from '@app/screens/configuration-management/components/redux/seletors';
import {createProRataMiscExpenseCreator, fetchExpenseTypeSettingGridCreator} from '@app/screens/configuration-management/components/redux/slice';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {appSelector} from '@app/store/selectors';
import {navbarCss} from '@app/components/widgets/navbar/navbarCss';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {fetchExportFileCreator} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {ExportButtons} from '@app/components/widgets/ExportButton/export-button';
import '@app/index.css';
import theme from '@app/themes';
import { strings } from '@app/common/strings';

export const ConfigurationManagementNavBar = () => {
  const dispatch = useDispatch();

  const isActiveState = useSelector(
    ConfigurationManagementSelector.getIsActiveState(),
  );

  const request = useSelector(filterSelector.getFilterOptions());
  const {isWriteAccess = false} = useSelector(appSelector.getUserInfo());

  const currentPage = useSelector(
    ConfigurationManagementSelector.getConfigurationManagementCurrentPage(),
  );

  const openExpenseTpyeSettingsPopup = () => {
    dispatch(
      configurationManagementStateActions.setaddExpenseConfigManagementPopupVisible(
        true,
      ),
    );
    dispatch(
      configurationManagementStateActions.setProRataMiscExpensePopupVisible(
        false,
      ),
    );
  };

  const openProRataMiscExpensePopup = () => {
    dispatch(
      configurationManagementStateActions.setProRataMiscExpensePopupVisible(
        true,
      ),
    );
    dispatch(
      configurationManagementStateActions.setProRataMiscExpensePopupType('Add'),
    );
    dispatch(
      configurationManagementStateActions.setaddExpenseConfigManagementPopupVisible(
        false,
      ),
    );
  };

  const manageAddNewPopup = useCallback(() => {
    trackEvent(EVENT_NAME_ADD_NEW_EXPENSE_DA_MASTER, {});
    currentPage === 0
      ? openExpenseTpyeSettingsPopup()
      : openProRataMiscExpensePopup();
  }, [currentPage]);
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  useEffect(() => {
    dispatch(
      filterStateActions.setFilterOptions({
        ...request,
        isActive: isActiveState,
      }),
    );
    if(navbarComponentHeading?.props?.children ===
          'Expense Type Settings'){
            dispatch(fetchExpenseTypeSettingGridCreator(null));
          }else{
            dispatch(createProRataMiscExpenseCreator(null))
          }
  }, [isActiveState]);
  const expenseSettingActiveInactive = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      trackEvent(EVENT_NAME_ACTIVE_BUTTON, {});

      dispatch(
        configurationManagementStateActions.setIsActiveState(
          event.target.checked,
        ),
      );
    },
    [],
  );
  useEffect(() => {
    return () => {
      dispatch(configurationManagementStateActions.setExpenseTypeSetting([]))
      dispatch(
        filterStateActions.setFilterOptions({
          isActive: true,
          searchText: '',
          pageNumber: 0,
          pageLimit: 100,
          divisionIds: [],
          designationIds: [],
          hqIds: [],
        }),
      );
    };
  }, []);
  const exportButtonSettings = (e: any) => {
    dispatch(fetchExportFileCreator(e.target.name));
  };
  return (
    <div style={navbarCss.containercss}>
      <div style={navbarCss.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          sx={{width: 300}}
          component="div"
          color={theme.colors.LIGHT_NAVY_BLUE}
          fontWeight={theme.sizing.weight800}
          display={'flex'}>
          {navbarComponentHeading}
        </Typography>
        {navbarComponentHeading?.props?.children ===
          'Expense Type Settings' && (
          <div style={navbarCss.flexSpaceBetween}>
            <ExportButtons
              onClick={exportButtonSettings}
              isWriteAccess={isWriteAccess}
              name={
               'expensetypesettings'
              }
            />
            <Button
              data-testid="add-config-new"
              variant="contained"
              disabled={!isWriteAccess}
              sx={navbarCss.button}
              onClick={manageAddNewPopup}>
             {strings.AddNew}
            </Button>
          </div>
        )}
      </div>
      <div style={navbarCss.flexSpaceBetween}>
        <div style={navbarCss.margintopcss}></div>

        <div style={navbarCss.margintopcss}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            paddingLeft={'7px'}>
            <Typography
                color={theme.colors.LIGHT_NAVY_BLUE}
                fontWeight={theme.sizing.weight800}
              fontFamily={theme.fonts.poppins}
              fontSize={theme.sizing.fontSize}>
             {strings.inActive}
            </Typography>
            <AntSwitch
              checked={isActiveState}
              onChange={expenseSettingActiveInactive}
              inputProps={{'aria-label': 'ant design'}}
            />
            <Typography
            color={theme.colors.LIGHT_NAVY_BLUE}
            fontWeight={theme.sizing.weight800}
              fontFamily={theme.fonts.poppins}
              fontSize={theme.sizing.fontSize}>
                {strings.active}
            </Typography>
          </Stack>
        </div>
      </div>
    </div>
  );
};
