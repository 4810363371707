import { createAction, createSlice } from "@reduxjs/toolkit";

export const configurationManagementInitialState = {
  currentPageExpenseDate: true,
  expenseType: [],
  expenseTypeSetting: [],
  editExpenseTypeSetting: [],
  expenseTypeSettingSnaShot: [],
  ExpenseTypeLoading: false,
  addExpenseConfigManagementPopupVisible: false,
  editExpenseConfigManagementPopupVisible: false,
  isActiveState: true,
  expenseDateLoading: false,
  expenseTypeDate: [],
  expenseDateError: false,
  expenseTypeSettingHq:[],
  expenseTypeSettingCreateValidation:[],
  pageNo:0,
  rowPerPage:100,
  totalCount:0,
  currentPage:0,
  proRataMiscExpensePopupVisibleAddNew:false,
  proRataMiscExpenseEdit:{},
  proRataMiscExpensePopupType:"",
  proRataMiscExpense:[],
};

export const fetchExpenseTypeDateGridCreator = createAction(
  "CONFIGURATION_MANAGEMENT_HANDLER/FETCH_EXPENSE_TYPE_DATE_GRID"
);

export const fetchExpenseTypeDateGridCreatorTypeName =
  fetchExpenseTypeDateGridCreator().type;

  export const saveExpenseTypeDateGridCreator = createAction<any>(
    "CONFIGURATION_MANAGEMENT_HANDLER/SAVE_EXPENSE_TYPE_DATE"
  );
  
  export const saveExpenseTypeDateGridCreatorCreatorTypeName =
    saveExpenseTypeDateGridCreator({}).type;

export const fetchExpenseTypeDropdownCreator = createAction<any>(
  "CONFIGURATION_MANAGEMENT_HANDLER/FETCH_EXPENSE_TYPE_DROPDOWN"
);
export const fetchExpenseTypeDropdownCreatorTypeName =
  fetchExpenseTypeDropdownCreator(null).type;

export const fetchExpenseTypeSettingGridCreator = createAction<any>(
  "CONFIGURATION_MANAGEMENT_HANDLER/FETCH_EXPENSE_TYPE_SETTING_GRID"
);
export const fetchExpenseTypeSettingGridCreatorTypeName =
  fetchExpenseTypeSettingGridCreator(null).type;

export const activeInactiveExpenseTypeSettingCreator = createAction<any>(
  "CONFIGURATION_MANAGEMENT_HANDLER/ACTIVE_INACTIVE"
);

export const activeInactiveExpenseTypeSettingCreatorCreatorTypeName =
  activeInactiveExpenseTypeSettingCreator({}).type;

export const createExpenseTypeSettingCreator = createAction<any>(
  "CONFIGURATION_MANAGEMENT_HANDLER/CREATE_EXPENSE_TYPE_SETTING"
);

export const createExpenseTypeSettingCreatorCreatorTypeName =
  createExpenseTypeSettingCreator({}).type;

  export const createProRataMiscExpenseCreator = createAction<any>(
    "CONFIGURATION_MANAGEMENT_HANDLER/CREATE_PRO_RATA_MISC_EXPENSE"
  );
  
  export const createProRataMiscExpenseCreatorTypeName =
  createProRataMiscExpenseCreator({}).type;

  export const editProRataMiscExpenseCreator = createAction<any>(
    "CONFIGURATION_MANAGEMENT_HANDLER/EDIT_PRO_RATA_MISC_EXPENSE"
  );
  
  export const editProRataMiscExpenseCreatorTypeName =
  editProRataMiscExpenseCreator({}).type;

  export const createExpenseTypeSettingValidationCreator = createAction<any>(
    "CONFIGURATION_MANAGEMENT_HANDLER/CREATE_EXPENSE_TYPE_SETTING_VALIDATION"
  );
  
  export const createExpenseTypeSettingValidationCreatorCreatorTypeName =
  createExpenseTypeSettingValidationCreator({}).type;

export const editExpenseTypeSettingCreator = createAction<any>(
  "CONFIGURATION_MANAGEMENT_HANDLER/EDIT_EXPENSE_TYPE_SETTING"
);

export const editExpenseTypeSettingCreatorCreatorTypeName =
  editExpenseTypeSettingCreator({}).type;

  export const fetchHqByEdividionDesginationxpenseTypeSettingCreator = createAction<any>(
    "CONFIGURATION_MANAGEMENT_HANDLER/HQ_EXPENSE_TYPE_SETTING"
  );
  
  export const fetchHqByEdividionDesginationxpenseTypeSettingCreatorTypeName =
  fetchHqByEdividionDesginationxpenseTypeSettingCreator({}).type;

const configurationManagementStateHandler = createSlice({
  name: "CONFIGURATION_MANAGEMENT_HANDLER",
  initialState: configurationManagementInitialState,
  reducers: {
    setconfigurationManagementPage: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        currentPageExpenseDate: payload,
      };
    },
    setExpenseType: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        expenseType: payload,
      };
    },
    setProRataMiscExpensePopupVisible:(prevState, action)=>{
      const {payload}= action;
      return {
        ...prevState,
        proRataMiscExpensePopupVisible:payload
      }
    },
    setProRataMiscExpensePopupType:(prevState, action)=>{
      const {payload}= action;
      return {
        ...prevState,
        proRataMiscExpensePopupType:payload
      }
    },
    setExpenseTypeSettingSnaShot: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        expenseTypeSettingSnaShot: payload,
      };
    },
    setEditExpenseTypeSetting: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        editExpenseTypeSetting: payload,
      };
    },
    setExpenseTypeSettingHq: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        expenseTypeSettingHq: payload,
      };
    },
    setPageNo: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        pageNo: payload,
      };
    },
    setTotalCount: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        totalCount: payload,
      };
    },
    
    setRowPerPage: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        rowPerPage: payload,
      };
    },
    setExpenseTypeSetting: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        expenseTypeSetting: payload,
      };
    },
    setIsActiveState: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        isActiveState: payload,
      };
    },
    setaddExpenseConfigManagementPopupVisible: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        addExpenseConfigManagementPopupVisible: payload,
      };
    },

    setExpenseTypeSettingCreateValidation : (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        expenseTypeSettingCreateValidation: payload,
      };
    },

    setEditExpenseConfigManagementPopupVisible: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        editExpenseConfigManagementPopupVisible: payload,
      };
    },
    setExpenseTypeLoading: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        ExpenseTypeLoading: payload,
      };
    },
    setExpenseDateLoading: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        expenseDateLoading: payload,
      };
    },
    setExpenseTypeDate: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        expenseTypeDate: payload,
      };
    },
    setExpenseDateError: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        expenseDateError: payload,
      };
    },
    setProRataMiscExpense: (prevState, action) => {
      const { payload } = action;
      return {
        ...prevState,
        proRataMiscExpense: payload,
      };
    },
    setCurrentPage:(prevState,action)=>{
      const {payload}= action;
      return{
        ...prevState,
        currentPage:payload,
      }
    },
    setProRataMiscExpenseEdit:(prevState,action)=>{
      const {payload}= action;
      return{
        ...prevState,
        proRataMiscExpenseEdit: payload,
      }
    },
  },
});
export const configurationManagementStateActions =
  configurationManagementStateHandler.actions;
export const configurationManagementStateReducer =
  configurationManagementStateHandler.reducer;
