import {DefaultTheme, makeStyles} from '@mui/styles';
import Colors from '@app/themes/colors';
import { maxWidth } from '@mui/system';

interface CheckBoxObj {
  status: string[];
  checked: boolean;
}

export const useStyles = makeStyles<DefaultTheme, CheckBoxObj>({
  boxWithTitle:{
    backgroundColor: Colors.white,
    border: '1px #e1e1e1 solid',
    padding:'20px',
    minHeight:250,
    width: 400,
    cursor:'pointer',
  },
  card:{
    backgroundColor:'transparent',
    borderRadius:0,
    boxShadow:'0px 0px 0px 0px'    
  },
  boxWithoutTitle:{
    backgroundColor: Colors.disabled,
  },
  title:{
    borderBottom: '1px #e1e1e1 solid',
    paddingBottom:10,
    marginBottom:10
  },
  dropdown: {
    height: 40,
  },
  root: {
    width: '160px',
    height: '40px',
    padding: '2px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    marginTop:1,
    '&$checked': {
      '& + $track': {
        backgroundColor: '#fff',
      },
    },
  },
  thumb: statusSelected => {
    return {
      color: '#fff',
      backgroundColor: '#332b77',
      width: '80px',
      height: '36px',
      margin: '0px',
      borderRadius: 20,
      left: '9px',
      '&:after, &:before': {
        color: '#fff',
        fontSize: '14px',
        position: 'absolute',
        top: '8px',
      },
      '&:after': {
        content: statusSelected.checked
          ? `'${statusSelected?.status[1]}'`
          : `'${statusSelected?.status[0]}'`,
        left: '15px',
      },
    };
  },
  track: statusSelected => {
    return {
      borderRadius: '20px',
      border: '1px solid #332b77 !important',
      color: '#fff',
      backgroundColor: '#fff!important',
      opacity: '1 !important',
      '&:after, &:before': {
        color: '#000',
        fontSize: '14px',
        position: 'absolute',
        top: '9px',
      },
      '&:after': {
        content: `'${statusSelected?.status[0]}'`,
        left: '20px',
      },
      '&:before': {
        content: `'${statusSelected?.status[1]}'`,
        right: '15px',
      },
    };
  },
  checked: {
    color: '#23bf58 !important',
    transform: 'translateX(76px) !important',
  },
  disable:{
   
  },
  small:{
    display: 'none',
  },
  nonElevatedCard: {
    boxShadow: 'none',
    overflow: 'visible',
  },
  toTextContainer:{
    marginTop:20,
    flexDirection:'row'
  },
  toText:{
    marginRight:5
  }
});
